import { DEFAULT_LOCATION, ZOOM_DEFAULT } from "./Constants";

export function getLatLngFromUrl(
  str,
  { _latLocation = "", _lngLocation = "" }
) {
  const _arr = str.split(",");
  const _lat = _arr[0] || "";
  const _lng = _arr[1] || "";
  const _zoom = _arr[2] || "";
  return {
    latitude:
      Number(_lat.substring(2, _lat.length)) ||
      Number(_latLocation) ||
      DEFAULT_LOCATION.lat,
    longitude: Number(_lng) || Number(_lngLocation) || DEFAULT_LOCATION.lng,
    zoom: Number(_zoom.substring(0, _zoom.length - 1)) || ZOOM_DEFAULT,
  };
}
