import { axiosInstance } from "../../network/apis";
import { API_KEY_ROUTING } from "../../utils/Constants";

const headers = {
  "Content-Type": "application/json",
  apikey: API_KEY_ROUTING,
};

const getRouteRequest = async (_params) => {
  return await axiosInstance.post("/route", _params, { headers });
};

const OperationActionApis = {
  getRouteRequest,
};

export default OperationActionApis;
