import { all } from "redux-saga/effects";

import { SuggestionSaga } from "../Suggestion/SubggestionSaga";
import {
  searchByTextAutoCompleteSaga,
  SearchByTextSaga,
  SearchDetailSaga,
  SearchLatLongSaga,
  SearchSaga,
} from "../Search/SearchSaga";
import {
  DirectUiSaga01,
  DirectUiSaga02,
  DirectUiSaga03,
  DirectUiSaga04,
} from "../Direct/DirectSaga";
import { ReverseSaga, ReverseV2Saga } from "../Reverse/ReverseSaga";
import {
  findPlaceSaga,
  sendDataTrackingSaga,
  updatePageSaga,
  updatePlaceByUrlSaga,
  updatePlaceSaga,
} from "../GlobalUi/GlobalUiSaga";
import { GetAddressDetailSaga } from "../AddressDetail/AddressDetailSaga";
import { GetRouteSaga } from "../Operation/OperationSaga";

export function* watchSagas() {
  //Combine sagas with
  yield all([
    SuggestionSaga(),
    SearchSaga(),
    DirectUiSaga01(),
    DirectUiSaga02(),
    DirectUiSaga03(),
    DirectUiSaga04(),
    ReverseSaga(),
    ReverseV2Saga(),
    SearchByTextSaga(),
    SearchLatLongSaga(),
    SearchDetailSaga(),
    searchByTextAutoCompleteSaga(),
    findPlaceSaga(),
    updatePlaceSaga(),
    updatePageSaga(),
    GetAddressDetailSaga(),
    GetRouteSaga(),
    sendDataTrackingSaga(),
    updatePlaceByUrlSaga(),
  ]);
  // OR
  // yield all([fork(SuggestionSaga)]);
}
