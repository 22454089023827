import { axiosInstance } from "network/apis";

const { API_TRACKING_URL } = require("utils/Constants");

const headers = {
  "Content-Type": "application/json",
  // apikey: API_TRACKING_URL,
};

export const trackingApis = {
  sendTracking: async (params) => {
    return await axiosInstance.post(`/api/v1/tracking/push`, params, {
      baseURL: API_TRACKING_URL,
      headers,
    });
  },
};
