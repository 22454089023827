export const GET_SEARCH_REQUEST = 'GET_SEARCH_REQUEST';
export const GET_SEARCH_RECEIVE = 'GET_SEARCH_RECEIVE';
export const ADD_SEARCH_RECEIVE = 'ADD_SEARCH_RECEIVE';

export const GET_SEARCH_BY_TEXT_AUTO_COMPLETE = 'GET_SEARCH_BY_TEXT_AUTO_COMPLETE';

export const GET_SEARCH_BY_TEXT_REQUEST = 'GET_SEARCH_BY_TEXT_REQUEST';
export const GET_SEARCH_BY_TEXT_RECEIVE = 'GET_SEARCH_BY_TEXT_RECEIVE';

export const GET_SEARCH_LAT_LONG_REQUEST = 'GET_SEARCH_LAT_LONG_REQUEST';
export const GET_SEARCH_LAT_LONG_RECEIVE = 'GET_SEARCH_LAT_LONG_RECEIVE';

export const GET_SEARCH_DETAIL_REQUEST = 'GET_SEARCH_DETAIL_REQUEST';
export const GET_SEARCH_DETAIL_RECEIVE = 'GET_SEARCH_DETAIL_RECEIVE';

export const UPDATE_SEARCH_LOADING = 'UPDATE_SEARCH_LOADING';
