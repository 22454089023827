import { trackingApis } from "service/tracking";
import {
  FEATURE_ID,
  objectIdTracking,
  osTracking,
  platformInfoTracking,
  platformTracking,
  platformTypeTracking,
  typeTracking,
} from "./Constants";
import isEmpty from "lodash/isEmpty";

export const sendTrackingInfo = ({ buttonTrack, action }) => {
  const data = [
    generateTrackingData({
      type: typeTracking.info,
      action,
      buttonTrack,
    }),
  ];

  sendTrackingDataList(data);
};

export const sendTrackingDataList = (data) => {
  try {
    trackingApis.sendTracking({ data });
  } catch (error) {}
};

export const generateTrackingData = ({
  type,
  action,
  buttonTrack,
  start,
  end,
  error,
  extra_info,
}) => {
  const feature_id = Number(FEATURE_ID) || FEATURE_ID; // Khai bao trong env - int
  const object_id = objectIdTracking.user_public; // int
  const user_id = getClientId(); // Tu dinh danh mot string khong qua 255 ky tu
  const platform = platformTracking.web; // int
  const platform_type = platformTypeTracking.web_maps; // int
  const platform_info = getPlatformInfo(); // string
  const os = getOs(); //  int
  const request_time = Date.now();

  let dataInfo = {
    feature_id,
    object_id,
    user_id,
    platform,
    platform_type,
    platform_info,
    os,
    type,
    action,
    request_time,
  };

  if (!!buttonTrack || !isEmpty(buttonTrack)) {
    const { buttonId, buttonName } = buttonTrack;
    dataInfo = { ...dataInfo, button_name: buttonName, button_id: buttonId };
  }

  if (!!extra_info) {
    dataInfo = { ...dataInfo, extra_info };
  }

  if (!!start && !end) {
    dataInfo = {
      ...dataInfo,
      start,
    };
  }

  if (!!end && !start) {
    dataInfo = { ...dataInfo, end };
  }

  if (type === typeTracking.error) {
    if (!!error || !isEmpty(error)) {
      dataInfo = {
        ...dataInfo,
      };

      if (error.errorId === "find_location_error") {
        dataInfo = {
          ...dataInfo,
          button_name: error.buttonName,
          button_id: error.buttonId,
          error_id: error.errorId,
          error_msg: error.errorMsg,
        };
      } else {
        dataInfo = {
          ...dataInfo,
          error_id: error.errorId,
          error_msg: error.errorMsg,
        };
      }
    }
  }

  return dataInfo;
};

const getPlatformInfo = () => {
  const browserInfo = navigator.userAgent;

  let browser = platformInfoTracking.anonymous;

  if (browserInfo.includes("Opera") || browserInfo.includes("Opr")) {
    browser = platformInfoTracking.opera;
  } else if (browserInfo.includes("Edg")) {
    browser = platformInfoTracking.edge;
  } else if (browserInfo.includes("Chrome")) {
    browser = platformInfoTracking.chrome;
  } else if (browserInfo.includes("Firefox")) {
    browser = platformInfoTracking.firefox;
  } else if (
    !browserInfo.includes("Chrome") &&
    browserInfo.includes("Safari")
  ) {
    browser = platformInfoTracking.safari;
  }

  return browser;
};

const getOs = () => {
  const osInfo = navigator.userAgent;
  let osTrack = osTracking.anonymous;

  if (osInfo.includes("Android")) {
    osTrack = osTracking.android;
  } else if (osInfo.includes("iPhone")) {
    osTrack = osTracking.iOS;
  } else if (osInfo.includes("Win")) {
    osTrack = osTracking.windows;
  } else if (osInfo.includes("Mac")) {
    osTrack = osTracking.mac;
  } else if (osInfo.includes("Linux")) {
    osTrack = osTracking.linux;
  }

  return osTrack;
};

function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const getClientId = () => {
  const clientId = localStorage.getItem("clientId");
  if (clientId) return clientId;
  const newId = generateUUID();

  localStorage.setItem("clientId", newId);
  return newId;
};
