import { call, put, delay, select } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";

// API
import API from "./SuggestionApis";

// Action
import * as ACTION_SUGGESTION from "./SuggestionAction";
import * as ACTION_GLOBAL from "../GlobalUi/GlobalUiAction";
import * as TYPES from "./SuggestionTypes";

// Util
import { dispatchSnackbarError } from "../../utils/Shared";
import { converVietNamese } from "../../utils/converVietNamese";
import { formatInputTextSuggestion } from "../../utils/convertText";
import { convertDataPoints } from "../../utils/convertDataPoint";

let _flat = false;

const getMaps = (state) => state.globalUi.map;
const getCurrentLocation = (state) => state.globalUi.currentLocation;
const getTabList = (state) => state.globalUi.tabList;

export function* getSuggestionFromCache(inputText) {
  try {
    // Hoàn cache.
    const itemString = localStorage.getItem("history-search");
    const cacheObject = JSON.parse(itemString) || [];
    const inputTextConvert = converVietNamese(inputText).toLowerCase().trim();

    const cacheFilter = cacheObject.filter((cache) => {
      if (cache.id) {
        const nameVN = converVietNamese(cache.name || cache.full_name)
          .toLowerCase()
          .trim();

        return nameVN.includes(inputTextConvert);
      }

      return false;
    });

    const cachesCopy = cacheFilter.splice(0, 3);
    const tabList = yield select(getTabList);

    const caches = convertDataPoints({
      items: cachesCopy,
      optionFix: [{ key: "isCache", value: true }],
      tabList,
    });

    return caches;
  } catch (e) {}
}

export function* sagasRequestSuggestion(action) {
  try {
    const { config } = action.payload;
    const inputText = formatInputTextSuggestion(action.payload?.inputText);

    // UpdateCache.
    const limit = config.limit || 5;
    const mode = config.mode || "around";

    const cachesBefore = yield getSuggestionFromCache(inputText);
    if (cachesBefore?.length > 0 && config.key) {
      yield put(
        ACTION_SUGGESTION.suggestionReceive({
          [config.key]: cachesBefore.slice(0, limit),
        })
      );
    }

    // debounce by 500ms
    if (_flat) {
      yield delay(500);
    }
    _flat = true;

    // Setup data
    const _maps = yield select(getMaps);
    const currentLocation = yield select(getCurrentLocation);

    const _centerPoint = _maps.centerPoint;

    const paramStringNear = {
      address: inputText ? inputText : null,
      lat: currentLocation?.lat || _centerPoint.latitude,
      lng: currentLocation?.lng || _centerPoint.longitude,
      size: limit,
      mode: mode,
    };

    // Call API
    const responseNear = yield call(API.getSuggetionList, paramStringNear);
    const tabList = yield select(getTabList);

    // Làm sạch dữ liệu trước khi đẩy vào store.
    const dataNear = responseNear.data?.data?.addresses || [];
    const dataList = [...dataNear];
    const dataConvertList = convertDataPoints({ items: dataList, tabList });

    // // Gom và ưu tiên cache.
    const caches = yield getSuggestionFromCache(inputText) || [];
    const _newData = [...caches, ...dataConvertList];

    // Filter dữ liệu trùng.
    const ids = _newData.map((v) => v.id);
    const idFilter = [...new Set(ids)];

    const __pointObjectNew = _newData.filter((item) => {
      if (item.id && idFilter.includes(item.id)) {
        idFilter.remove(item.id);
        return true;
      }

      return false;
    });

    // TODO: Nên chuyển kich ban chon ra ngoai worker nay.
    if (config && config.selected) {
      yield put(
        ACTION_GLOBAL.actionUpdatePlace({
          type: "click",
          data: __pointObjectNew[0],
        })
      );
    }

    if (config.key) {
      yield put(
        ACTION_SUGGESTION.suggestionReceive({
          [config.key]: __pointObjectNew.slice(0, limit),
        })
      );
    }
  } catch (err) {
    const _data = err.response && err.response.data;
    dispatchSnackbarError(_data);
  }
}

export function* SuggestionSaga() {
  yield takeLatest(TYPES.GET_SUGGESTION_REQUEST, sagasRequestSuggestion);
}
