import * as types from "./GlobalUiType";

const INITIAL_STATE = {
  point: {
    visible: false,
    data: {
      name: "",
      latitude: "",
      longitude: "",
    },
  },
  statusLayoutLeft: false,
  placeReported: null,
  currentLocation: {},
  tabList: [],
  accessDataTracking: [],
};

const GlobalUiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.UPDATE_MAP_DATA:
      return {
        ...state,
        map: {
          ...state.map,
          ...action.payload,
        },
      };
    case types.UPDATE_STATUS_LEFT:
      return {
        ...state,
        statusLeft: action.payload,
      };
    case types.UPDATE_STATUS_LAYOUT_LEFT:
      return {
        ...state,
        statusLayoutLeft: action.payload,
      };
    case types.UPDATE_TAB_LEFT:
      return {
        ...state,
        tabLeft: action.payload,
      };
    case types.UPDATE_PLACE: {
      return {
        ...state,
        place: action.payload,
      };
    }
    case types.UPDATE_POINT_GLOBAL: {
      return {
        ...state,
        point: action.payload,
      };
    }
    case types.UPDATE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case types.UPDATE_LOADING_DIRECT:
      return {
        ...state,
        loadingRedirect: action.payload,
      };
    case types.UPDATE_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case types.UPDATE_TEXT_INPUT:
      return {
        ...state,
        textInput: action.payload,
      };

    case types.PLACE_REPORTED:
      return {
        ...state,
        placeReported: action.payload,
      };
    case types.CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };

    case types.ACCESS_DATA_TRACKING:
      return {
        ...state,
        accessDataTracking: action.payload,
      };
    case types.SEND_DATA_TRACKING:
      return {
        ...state,
        dataTracking: action.payload,
      };
    case types.UPDATE_PLACE_BY_URL:
      return {
        ...state,
        place: action.payload,
      };
    case types.TAB_LIST:
      return {
        ...state,
        tabList: action.payload,
      };
    default:
      return state;
  }
};

export default GlobalUiReducer;
