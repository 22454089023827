import * as types from "./AddressDetailTypes";

const INITIAL_STATE = {};

// Replace with you own reducer
const addressDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ADDRESS_DETAIL:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};

export default addressDetailReducer;
