import {axiosInstance} from '../../network/apis';
import {API_KEY_DETAIL} from "../../utils/Constants";

const headerSearchDetail = {
  "Content-Type": "application/json",
  "api-key": API_KEY_DETAIL,
};

export const getAddressDetailRequest = async (params) => {
  return await axiosInstance.get(`/details.php${params}`, {headers: headerSearchDetail});
};