import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import history from "./History";
import * as LazyComponent from "../utils/LazyLoaded";
import Loader from "../components/Loader/Loader";
import PrivateRoute from "./PrivateRoute";
import PartnerRoute from "./PartnerRoute";

const Routes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Router history={history}>
        <Switch>
          <Route path="/callback">
            <LazyComponent.Callback />
          </Route>
          <Route path="/login">
            <LazyComponent.Login />
          </Route>
          <PartnerRoute path="/partner">
            <LazyComponent.MapPartner />
          </PartnerRoute>
          <PrivateRoute path="/">
            <LazyComponent.Map />
          </PrivateRoute>
          <Route path="/direct">
            <LazyComponent.Direct />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  );
};

export default Routes;
