import { axiosInstance, geoInstance } from "../../network/apis";
import {
  API_KEY_SUGGESTION,
  REVERVE_PROXY,
  REVERVE_PROXY_API_KEY,
} from "../../utils/Constants";

const headerSuggestion = {
  "Content-Type": "application/json",
  apikey: API_KEY_SUGGESTION,
};

const getSuggestionRequest = async (paramString) => {
  return await axiosInstance.get(`/pregeo/suggest${paramString}`, {
    headers: headerSuggestion,
  });
};

const SuggestionApis = {
  getSuggestionRequest,
  getSuggetionList: async (params) =>
    await geoInstance.get(`${REVERVE_PROXY}/spaces/api/v3/geocode`, {
      params,
      headers: {
        "x-api-key": REVERVE_PROXY_API_KEY,
      },
    }),
  getSuggetionListNear: async (params) =>
    await geoInstance.get(
      `${REVERVE_PROXY}/spaces/api/v3/geocode/reverse-geocoding`,
      {
        params,
        headers: {
          "x-api-key": REVERVE_PROXY_API_KEY,
        },
      }
    ),
};

export default SuggestionApis;
