const redirectUri = `${window.location.protocol}//${window.location.host}/callback`;

const oidc = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  issuer: process.env.REACT_APP_AUTH_SERVER,
  redirectUri,
  scopes: ["openid", "gmap:internal-api.access", "gmap:route.access"],
  pkce: true,
  isAuthzBackground: false,
  authnUrl: process.env.REACT_APP_AUTH_URL,
  state: "admingmap",
};

export default oidc;
