import store from "../../store";
import { loader } from "../../store/Loader/LoaderAction";
import { AuthRedirectGuardMiddleware } from "gmjs";
import oidc from "configs/oidc";
import { generateTrackingData, sendTrackingDataList } from "utils/tracking";
import { actionTracking, errorTracking, typeTracking } from "utils/Constants";

export const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};

export const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    // Modify request here
    store.dispatch(loader(true));
  }
  return request;
};

export const successHandler = (response) => {
  if (isHandlerEnabled(response)) {
    // Hanlde Response
    store.dispatch(loader(false));
  }
  return response;
};

export const errorHandler = async (error, axiosInstance) => {
  if (isHandlerEnabled(error.config)) {
    store.dispatch(loader(false));
    // You can decide what you need to do to handle errors.
    // here's example for unautherized user to log them out .
    // error.response.status === 401 && Auth.logout();
    const data = [];

    if (error?.response?.status === 401) {
      const pathname = window?.location?.pathname || "/";
      const authRedirectGuardMiddleware = AuthRedirectGuardMiddleware(oidc);
      authRedirectGuardMiddleware.run(pathname, "/");
    }

    if (
      error?.config?.baseURL !== "https://bigdata-ingestion-api.ghtklab.com"
    ) {
      if (error.response) {
        const keyError = Object.keys(errorTracking)
          .filter((item) => Number(item))
          .map((item) => Number(item));

        if (keyError.includes(error?.response?.status)) {
          const dataTracking = generateTrackingData({
            type: typeTracking.error,
            action: actionTracking.crash,
            error: errorTracking[error?.response?.status],
          });

          data.push(dataTracking);
        }
      } else {
        const errorDataTracking = generateTrackingData({
          type: typeTracking.error,
          action: actionTracking.crash,
          error: errorTracking[503],
        });

        data.push(errorDataTracking);
      }

      if (data.length) {
        sendTrackingDataList(data);
      }
    }
  }
  return Promise.reject({ ...error });
};
