import * as types from "./DirectType";

export const actionUpdatePoints = (payload) => ({
  type: types.UPDATE_POINTS,
  payload,
});

export const actionUpdatePoint = (payload) => ({
  type: types.UPDATE_POINT,
  payload,
});

export const actionUpdatePointByType = (payload) => ({
  type: types.UPDATE_POINT_BY_TYPE,
  payload,
});

export const actionUpdateRedirect = (payload) => ({
  type: types.GET_DIRECT_RECEIVE,
  payload,
});

// Update driect.
export const actionUpdateRevertPoint = (payload) => ({
  type: types.UPDATE_REVERT_POINT,
  payload,
});

export const actionUpdateProfile = (payload) => ({
  type: types.UPDATE_PROFILE,
  payload,
});

export const actionUpdatePathIndex = (payload) => ({
  type: types.UPDATE_PATH_INDEX,
  payload,
});

export const actionUpdatePathInstructionIndex = (payload) => ({
  type: types.UPDATE_PATH_INSTRUCTION_INDEX,
  payload,
});

export const actionClear = (payload) => ({
  type: types.CLEAR,
  payload,
});

export const actionClearLines = (payload) => ({
  type: types.CLEAR_LINES,
  payload,
});

export const actionUpdateLoading = (payload) => ({
  type: types.UPDATE_LOADING,
  payload,
});

export const actionUpdateInputFocusId = (payload) => ({
  type: types.UPDATE_INPUT_FOCUS_ID,
  payload,
});
