// Place - lat, lon
import * as types from "./ReverseType";

export const actionGetReverse = (payload) => ({
    type: types.GET_REVERSE,
    payload,
});

export const actionGetReverseV2 = (payload) => ({
    type: types.GET_REVERSE_V2,
    payload,
});
