import * as types from "./GlobalUiType";

// Place
export const actionUpdatePlace = (payload) => ({
  type: types.UPDATE_PLACE,
  payload,
});

export const actionFindPlace = (payload) => ({
  type: types.FIND_PLACE,
  payload,
});

// Point
export const actionUpdatePoint = (payload) => ({
  type: types.UPDATE_POINT_GLOBAL,
  payload,
});

// Status Left.
export const actionUpdateStatusLeft = (payload) => ({
  type: types.UPDATE_STATUS_LEFT,
  payload,
});

// Status Left.
export const actionUpdateLayoutStatusLeft = (payload) => ({
  type: types.UPDATE_STATUS_LAYOUT_LEFT,
  payload,
});

// Status Left.
export const actionUpdateTabLeft = (payload) => ({
  type: types.UPDATE_TAB_LEFT,
  payload,
});

// Map data.
export const actionUpdateMapData = (payload) => ({
  type: types.UPDATE_MAP_DATA,
  payload,
});

// Current Page
export const actionUpdatePage = (payload) => ({
  type: types.UPDATE_PAGE,
  payload,
});

// Loading direct
export const actionUpdateLoadingDirect = (payload) => ({
  type: types.UPDATE_LOADING_DIRECT,
  payload,
});

// Location
export const actionUpdateLocation = (payload) => ({
  type: types.UPDATE_LOCATION,
  payload,
});

// Text Input
export const actionUpdateTextInput = (payload) => ({
  type: types.UPDATE_TEXT_INPUT,
  payload,
});

export const actionUpdatePlaceReported = (payload) => ({
  type: types.PLACE_REPORTED,
  payload,
});

export const actionUpdateCurrentLocation = (payload) => ({
  type: types.CURRENT_LOCATION,
  payload,
});

export const actionUpdateAccessDataTracking = (payload) => ({
  type: types.ACCESS_DATA_TRACKING,
  payload,
});

export const actionSendTracking = (payload) => ({
  type: types.SEND_DATA_TRACKING,
  payload,
});

export const actionUpdatePlaceByUrl = (payload) => ({
  type: types.UPDATE_PLACE_BY_URL,
  payload,
});

export const actionUpdateTabList = (payload) => ({
  type: types.TAB_LIST,
  payload,
});
