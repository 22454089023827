import { useEffect, useState } from "react";
import { Route } from "react-router-dom";

const PartnerRoute = ({ children }) => {
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    window.addEventListener("message", handleLoginInfo);

    const accessTokenObj = JSON.parse(
      localStorage.getItem("ghtk-token-storage") || "{}"
    );

    if (!accessToken) {
      setAccessToken(accessTokenObj?.accessToken?.accessToken || "");
    }

    return () => {
      window.removeEventListener("message", handleLoginInfo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoginInfo = (e) => {
    if (e.data.type !== "partner") return;
    localStorage.setItem(
      "ghtk-token-storage",
      JSON.stringify({ accessToken: { ...e.data.accessToken } })
    );

    localStorage.setItem("user", JSON.stringify({ ...e.data.user }));
    localStorage.setItem("isPartner", JSON.stringify(true));

    if (e?.data?.geoJsonDataPreview) {
      localStorage.setItem(
        "geo-json-data-preview",
        JSON.stringify({ ...e.data.geoJsonDataPreview })
      );
    }

    if (e?.data?.addressPreview) {
      localStorage.setItem(
        "address-geo-json-preview",
        JSON.stringify({ ...e.data.addressPreview })
      );
    }

    setAccessToken(e.data.accessToken.accessToken || "");
  };

  return (
    // <Route
    //   render={({ location }) =>
    //     accessToken && accessToken.length ? children : <></>
    //   }
    // />
    <Route>{children}</Route>
  );
};

export default PartnerRoute;
