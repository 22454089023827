export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const TITLE_SERVER = process.env.REACT_APP_TITLE_SERVER;
export const RESEVER_URL = process.env.REACT_APP_RESEVER_URL;
export const GATEWAY_PUBLIC_URL = process.env.REACT_APP_GATEWAY_PUBLIC_URL;
export const REVERVE_PROXY = process.env.REACT_APP_REVERVE_PROXY;
export const REVERVE_PROXY_API_KEY =
  process.env.REACT_APP_REVERVE_PROXY_API_KEY;

export const API_KEY_REVERSE = process.env.REACT_APP_API_KEY_REVERSE;
export const API_KEY_SUGGESTION = process.env.REACT_APP_API_KEY_SUGGESTION;
export const API_KEY_ROUTING = process.env.REACT_APP_API_KEY_ROUTING;
export const API_KEY_SEARCH = process.env.REACT_APP_API_KEY_SEARCH;
export const API_KEY_DETAIL = process.env.REACT_APP_API_KEY_DETAIL;
export const OS_URL = process.env.REACT_APP_OS_URL;
export const GCHAT_API_URL = process.env.REACT_APP_GCHAT_APIURL;
export const API_TRACKING_URL = process.env.REACT_APP_TRACKING_URL;
export const FEATURE_ID = process.env.REACT_APP_TRACKING_FEATURE_ID;

export const VERSION = "v0.0.2";

export const API_OPERATION = {
  GET_ADDRESS: "/v1/address",
  GET_COD: "/api/cod/get-detail-cods-by-station",
  GET_STATION: "/api/delivering/get-station-by-ward",
  GET_LIST_POINT_COD: "/api/manage-cod/get-list-point-cod-operation",
  GET_COD_ROUTE: "/v1/cod_location",
};

export const OS = {
  DELIVERY: "delivery",
  PICK: "pick",
};
export const OS_STATUS = {
  delay: "Delay",
  doing: "Đang",
  complete: "Hoàn thành",
  cancel: "Huỷ",
};
export const OS_STATISTIC = {
  total: "Phát sinh",
  doing: "Đang",
  complete: "Hoàn thành",
  delay: "Delay",
  cancel: "Huỷ",
};

export const OS_PACKAGE_URL =
  "https://admin.giaohangtietkiem.vn/admin?alias=S21100913.BO.MT16-22-Q06.";

export const GCHAT = {
  CREATE_CHANNEL: "/v3/channels",
  GET_ME: "/v3/auth/access_token",
};

export const OPTIMIZE_OPTION = {
  TIME: "TIME",
  DISTANCE: "DISTANCE",
};

export const PROFILES = ["bike", "motorcycle", "foot", "car"];

export const API_GLOBAL = {
  REPORT: "/gapis/api/report",
};

export const objectIdTracking = {
  shop: 1,
  buyer: 2,
  cod: 3,
  nvvh: 4,
  cs: 5,
  user_public: 6,
};

export const typeTracking = {
  info: 1,
  error: 2,
};

export const platformTracking = {
  app: 1,
  web: 2,
};

export const platformTypeTracking = {
  GHTK_App: 1,
  GHTK_Pro: 2,
  moshop: 3,
  iGHTK: 4,
  web_customer: 5,
  web_moshop: 6,
  app_noi_bo: 7,
  drivers_X: 8,
  app_drivers: 9,
  app_GHTK_go: 10,
  app_gchats: 11,
  web_admin: 12,
  web_gchats: 13,
  app_maps: 14,
  web_maps: 15,
};

export const platformInfoTracking = {
  safari: "apple_safari",
  firefox: "mozilla_firefox",
  chrome: "google_chrome",
  edge: "microsoft_edge",
  opera: "opera",
  anonymous: "anonymous",
};

export const osTracking = {
  android: 1,
  iOS: 2,
  windows: 3,
  mac: 4,
  linux: 5,
  anonymous: 6,
};

export const actionTracking = {
  view: 1,
  click: 2,
  start_button: 3,
  end_button: 4,
  exit: 5,
  crash: 6,
};

export const buttonTracking = {
  find_my_location: {
    buttonId: "1.0",
    buttonName: "Vị trí định vị",
  },
  "3D": { buttonId: "1.1", buttonName: "Bản đồ 3D" },
  layer: { buttonId: "1.2", buttonName: "Lớp bản đồ" },
  terrain: { buttonId: "1.2.1", buttonName: "Bản đồ địa hình" },
  hover_location: {
    buttonId: "1.3",
    buttonName: "Đia điểm bản đồ",
  },
  hover_direction: {
    buttonId: "1.3.1",
    buttonName: "Chỉ đường địa điểm bản đồ",
  },
  hover_sharing: {
    buttonId: "1.3.2",
    buttonName: "Chia sẻ địa điểm bản đồ",
  },
  hover_report: {
    buttonId: "1.3.3",
    buttonName: "Báo sai địa điểm bản đồ",
  },
  route_direction: {
    buttonId: "2.0",
    buttonName: "Chỉ đường",
  },
  search_box: { buttonId: "2.1", buttonName: "Box tìm kiếm" },
  search_location: {
    buttonId: "2.1.1",
    buttonName: "Tìm kiếm địa điểm",
  },
  suggest_location: {
    buttonId: "2.1.2",
    buttonName: "Chọn điểm suggest",
  },
  add_location: {
    buttonId: "2.2",
    buttonName: "Thêm điểm đến",
  },
  revese_route: {
    buttonId: "2.3",
    buttonName: "Hoán đổi điểm",
  },
  choose_transport: {
    buttonId: "2.4",
    buttonName: "Phương tiện di chuyển",
  },
  change_route: {
    buttonId: "2.5",
    buttonName: "Đổi lộ trình",
  },
  location_direction: {
    buttonId: "3.0",
    buttonName: "Chỉ đường địa điểm tìm kiếm",
  },
  location_sharing: {
    buttonId: "3.1",
    buttonName: "Chia sẻ địa điểm tìm kiếm",
  },
  location_report: {
    buttonId: "3.2",
    buttonName: "Báo sai địa điểm tìm kiếm",
  },
  select_suggest_destination: {
    buttonId: "4.0",
    buttonName: "Điểm đặc biệt",
  },
  scroll_suggest_destination: {
    buttonId: "4.1",
    buttonName: "Scroll điểm đặc biệt",
  },
  chat_cskh: { buttonId: "5.0", buttonName: "Chat CSKH" },
  close_report: { buttonId: "5.1", buttonName: "Tắt popup" },
  report_location: { buttonId: "5.2", buttonName: "Gửi lỗi" },
  done_route: {
    buttonId: "6.0",
    buttonName: "Hoàn thành lộ trình",
  },
};

export const errorTracking = {
  400: { errorId: 400, errorMsg: "Bad request" },
  401: { errorId: 401, errorMsg: "Unauthorised" },
  403: { errorId: 403, errorMsg: "Permission denied" },
  404: { errorId: 404, errorMsg: "Not found" },
  500: { errorId: 500, errorMsg: "Internal Server Error" },
  502: { errorId: 502, errorMsg: "Bad Gateway" },
  503: { errorId: 503, errorMsg: "Service Unavailable" },
  504: { errorId: 504, errorMsg: "Gateway Timeout" },
  find_location_error: {
    buttonId: "1.0.1",
    buttonName: "Lỗi định vị",
    errorId: "find_location_error",
    errorMsg: "location not available",
  },
};

export const ZOOM_DEFAULT = 15;

export const DEFAULT_LOCATION = {
  lat: 16.0494762,
  lng: 108.2081116,
};

export const DATA_CONFIG_STATUS = {
  INVISIBLE: 0,
  VISIBLE: 1,
  GONE: 2,
};

export const platformInfo = {
  safari: "apple_safari",
  firefox: "mozilla_firefox",
  chrome: "google_chrome",
  edge: "microsoft_edge",
  opera: "opera",
  anonymous: "anonymous",
};

export const userOs = {
  window_phone: "windows phone",
  window: "win",
  android: "android",
  iOS: "iPad|iPhone|iPod",
  macOS: "mac",
  linux: "Linux",
};

export const PARTNER_ENDPOINT = {
  GET_POINTS: "/spaces/api/v1/point-distribution",
};

export const CONFIG_TYPE_DEFAULT_IMAGE = "Hình ảnh";
export const CONFIG_TYPE_DATE = "Date";
export const CONFIG_TYPE_IMAGE = "Image";
export const CONFIG_TYPE_DETAIL = "Địa chỉ chi tiết";

export const NUMBER_GENERAL_INFO = 7;

export const GDM_END_POINT = {
  GDM_CONFIG_DATA: "/gdm/api/v1/config/data",
  INFO_WINDOW: "spaces/api/v1/address/info-window",
};
