import * as types from "./AddressDetailTypes";

//Replace action name and update action types
export const actionSetAddressDetail = (payload) => ({
  type: types.SET_ADDRESS_DETAIL,
  payload
});


export const actionGetAddressDetail = (payload) => ({
  type: types.GET_ADDRESS_DETAIL,
  payload,
});