// Replace with your request types
export const UPDATE_PLACE = "UPDATE_PLACE";
export const FIND_PLACE = "FIND_PLACE";
export const UPDATE_POINT_GLOBAL = "UPDATE_POINT_GLOBAL";
export const UPDATE_STATUS_LEFT = "UPDATE_STATUS_LEFT";
export const UPDATE_STATUS_LAYOUT_LEFT = "UPDATE_STATUS_LAYOUT_LEFT";
export const UPDATE_MAP_DATA = "UPDATE_MAP_DATA";
export const UPDATE_TAB_LEFT = "UPDATE_TAB_LEFT";
export const UPDATE_PAGE = "UPDATE_PAGE";
export const UPDATE_LOADING_DIRECT = "UPDATE_LOADING_DIRECT";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_TEXT_INPUT = "UPDATE_TEXT_INPUT";
export const PLACE_REPORTED = "PLACE_REPORTED";
export const CURRENT_LOCATION = "CURRENT_LOCATION";
export const ACCESS_DATA_TRACKING = "ACCESS_DATA_TRACKING";
export const SEND_DATA_TRACKING = "SEND_DATA_TRACKING";
export const UPDATE_PLACE_BY_URL = "UPDATE_PLACE_BY_URL";
export const TAB_LIST = "TAB_LIST";
