import axios from "axios";
import { successHandler, errorHandler } from "../interceptors";
import {
  BASE_URL,
  REVERVE_PROXY,
  REVERVE_PROXY_API_KEY,
} from "../../utils/Constants";
import { generateUUID, getOSAndVersion, getPlatformInfo } from "utils";

const { os, version } = getOSAndVersion();
const platform = getPlatformInfo();

const localUserIp = localStorage.getItem("user-device-ip") || "N/A";

const localUserId = localStorage.getItem("user-device-id") || "";
const deviceId = localUserId ? localUserId : generateUUID();

if (!localUserId) {
  localStorage.setItem("user-device-id", deviceId);
}

const headers = {
  "Content-Type": "application/json",
  "Device-Name": platform,
  "Device-Id": deviceId,
  Os: os,
  "Os-Version": version ? version : "N/A",
  Ip: localUserIp,
};

//add your BASE_URL to Constants file
export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers,
});

// Handle request process
axiosInstance.interceptors.request.use((config) => {
  const accessTokenObj = JSON.parse(
    localStorage.getItem("ghtk-token-storage") || "{}"
  );
  const accessToken =
    Object.keys(accessTokenObj).length > 0
      ? accessTokenObj?.accessToken?.accessToken
      : null;

  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";
  config.headers.token = accessToken ? `Bearer ${accessToken}` : "";
  return config;
});
// Handle response process
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error, axiosInstance)
);

// // Config cho client tự động refresh token hoặc đăng nhập lại nếu token expired.
// axiosInstance.interceptors.response.use(
//   CatchTokenExpiredMiddleware.catchResponse.bind(CatchTokenExpiredMiddleware),
//   CatchTokenExpiredMiddleware.catchResponse.bind(CatchTokenExpiredMiddleware)
// );

//add your GATEWAY_PUBLIC_URL to Constants file
export const geoInstance = axios.create({
  baseURL: REVERVE_PROXY,
  // baseURL: "http://10.110.80.180:31165",
  headers: {
    ...headers,
    "x-api-key": REVERVE_PROXY_API_KEY,
  },
});

// Handle request process
geoInstance.interceptors.request.use((config) => {
  const accessTokenObj = JSON.parse(
    localStorage.getItem("ghtk-token-storage") || "{}"
  );
  const userObj = JSON.parse(localStorage.getItem("user") || "{}");
  const accessToken =
    Object.keys(accessTokenObj).length > 0
      ? accessTokenObj?.accessToken?.accessToken
      : null;

  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";
  config.headers.token = accessToken ? `Bearer ${accessToken}` : "";
  if (userObj?.tenant_id) {
    config.headers["x-tenant-id"] = userObj?.tenant_id;
  }
  return config;
});
// Handle response process
geoInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error, geoInstance)
);
