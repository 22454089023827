import * as types from "./OperationType";

const INITIAL_STATE = {};

const OperationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_ROUTE:
      return {
        ...state,
        coordinates: action.payload.points,
      };

    case types.UPDATE_ROUTE:
      return {
        ...state,
        points: action.payload.gh_responses[0].paths[0].points.coordinates,
      };

    case types.CLEAR: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default OperationReducer;
