import * as types from "./DirectType";
import { decoded } from "../../utils/graphhopper";

const INITIAL_STATE = {
  data: [
    {
      id: 1,
      type: "startPoint",
      content: {},
    },
    {
      id: 2,
      type: "endPoint",
      content: {},
    },
  ],
  lines: {},
  inputFocusId: null,
  searchParam: {},
  isLoading: false,
  profile: "optimal",
};

const DirectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.UPDATE_POINTS:
      return {
        ...state,
        data: [...action.payload],
      };

    case types.UPDATE_POINT: {
      const _data = state.data;
      const { idInput, data } = action.payload;

      const __data = _data.map((item) =>
        `${item.id}` === `${idInput}`
          ? {
              id: idInput,
              type: item.type,
              content: data,
            }
          : item
      );
      return {
        ...state,
        data: __data,
      };
    }
    case types.GET_DIRECT_RECEIVE: {
      var { profile, paths } = action.payload?.data;

      //decoded trường points
      paths = paths.map((path) => {
        const points = decoded(path?.points) || [];

        return {
          ...path,
          points: {
            coordinates: [...points],
            type: "LineString",
          },
        };
      });

      return {
        ...state,
        lines: {
          ...action.payload,
          paths,
          optimalProfile: profile || null,
        },
      };
    }
    case types.UPDATE_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      };
    }
    case types.UPDATE_PATH_INDEX: {
      return {
        ...state,
        pathIndex: action.payload,
      };
    }
    case types.UPDATE_PATH_INSTRUCTION_INDEX: {
      return {
        ...state,
        pathInstructionIndex: action.payload,
      };
    }
    case types.CLEAR: {
      return INITIAL_STATE;
    }

    case types.CLEAR_LINES: {
      return {
        ...state,
        lines: {},
      };
    }
    case types.UPDATE_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case types.UPDATE_INPUT_FOCUS_ID: {
      return {
        ...state,
        inputFocusId: action.payload,
      };
    }
    default:
      return state;
  }
};

export default DirectReducer;
