import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import ThemeApp from "./Theme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/dayjs";
import { SnackbarProvider } from "notistack";

import "maplibre-gl/dist/maplibre-gl.css";
import "./index.css";

ReactDOM.render(
  <SnackbarProvider
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
  >
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Provider store={store}>
        <ThemeApp />
      </Provider>
    </MuiPickersUtilsProvider>
  </SnackbarProvider>,
  document.querySelector("#root")
);
