export const UPDATE_POINTS = "UPDATE_POINTS";
export const UPDATE_POINT = "UPDATE_POINT";

export const UPDATE_POINT_BY_TYPE = "UPDATE_POINT_BY_TYPE";

export const GET_DIRECT_RECEIVE = "GET_DIRECT_RECEIVE";

export const UPDATE_REVERT_POINT = "UPDATE_REVERT_POINT";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PATH_INDEX = "UPDATE_PATH_INDEX";
export const UPDATE_PATH_INSTRUCTION_INDEX = "UPDATE_PATH_INSTRUCTION_INDEX";

export const CLEAR = "CLEAR";
export const CLEAR_LINES = "CLEAR_LINES";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_INPUT_FOCUS_ID = "UPDATE_INPUT_FOCUS_ID";
