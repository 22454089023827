import * as types from "./SearchTypes";

export const searchRequest = (payload) => ({
    type: types.GET_SEARCH_REQUEST,
    payload,
});

export const searchReceive = (payload) => ({
    type: types.GET_SEARCH_RECEIVE,
    payload,
});

export const addSearchReceive = (payload) => ({
    type: types.ADD_SEARCH_RECEIVE,
    payload,
});

export const searchByTextAutoComplete = (payload) => ({
    type: types.GET_SEARCH_BY_TEXT_AUTO_COMPLETE,
    payload,
});

export const searchByTextRequest = (payload) => ({
    type: types.GET_SEARCH_BY_TEXT_REQUEST,
    payload,
});

export const searchByTextReceive = (payload) => ({
    type: types.GET_SEARCH_BY_TEXT_RECEIVE,
    payload,
});

export const searchLatLngRequest = (payload) => ({
    type: types.GET_SEARCH_LAT_LONG_REQUEST,
    payload,
});

export const searchLatLngReceive = (payload) => ({
    type: types.GET_SEARCH_LAT_LONG_RECEIVE,
    payload,
});

export const searchLoading = (payload) => ({
    type: types.UPDATE_SEARCH_LOADING,
    payload,
});

export const searchDetailRequest = (payload) => ({
    type: types.GET_SEARCH_DETAIL_REQUEST,
    payload,
});

export const searchDetailReceive = (payload) => ({
    type: types.GET_SEARCH_DETAIL_RECEIVE,
    payload,
});
