import * as types from "./SearchTypes";

const INITIAL_STATE = {};

const SearchReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_SEARCH_RECEIVE:
            return {
                ...state,
                data: action.payload
            };
        case types.ADD_SEARCH_RECEIVE:
            return {
                ...state,
                dataDetail: action.payload
            };
        case types.GET_SEARCH_BY_TEXT_RECEIVE:
            return {
                ...state,
                dataText: action.payload
            };
        case types.UPDATE_SEARCH_LOADING:
            return {
                ...state,
                dataLoading: action.payload
            };
        default:
            return state;
    }
};

export default SearchReducer;
