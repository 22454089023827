import { axiosInstance, geoInstance } from "../../network/apis";
import {
  API_KEY_REVERSE,
  RESEVER_URL,
  REVERVE_PROXY,
  GDM_END_POINT,
} from "../../utils/Constants";

const accessTokenObj = JSON.parse(
  localStorage.getItem("ghtk-token-storage") || "{}"
);
const accessToken =
  Object.keys(accessTokenObj).length > 0
    ? accessTokenObj?.accessToken?.accessToken
    : null;

const headers = {
  "Content-Type": "application/json",
  apikey: API_KEY_REVERSE,
};

const headersV2 = {
  "Content-Type": "application/json",
  Authorization: accessToken ? `Bearer ${accessToken}` : "",
  apikey: API_KEY_REVERSE,
};

const getReverseRequest = async (params) => {
  return await geoInstance.get(
    `${REVERVE_PROXY}/spaces/api/v3/geocode/reverse-geocoding`,
    { params }
  );
};

const getReverseV2Request = async (_params) => {
  return await axiosInstance.get(
    `${RESEVER_URL}/internal-api/v1/reverse/reverse_ghtk_address?${_params}`,
    { headers: headersV2 }
  );
};

const getPartnerInfoWindow = async (params) =>
  await geoInstance.get(GDM_END_POINT.INFO_WINDOW, {
    params,
  });

const ReverseApis = {
  getReverseRequest,
  getReverseV2Request,
  getReversePoint: async (params) =>
    await geoInstance.get(
      `${REVERVE_PROXY}/spaces/api/v3/geocode/reverse-geocoding`,
      { params }
    ),
  getPartnerInfoWindow,
};

export default ReverseApis;
