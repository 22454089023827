import { call, put, select } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";
import _ from "lodash";

// API
import API from "./OperationActionApis";

// Action
import * as TYPES from "./OperationType";
import { updateRoute } from "./OperationAction";

// Util
import { dispatchSnackbarError } from "../../utils/Shared";

// Get routing
export function* getRoute(action) {
  try {
    const {
      points,
      vehicle,
      request_id,
      calc_points,
      points_encoded,
      locale,
      algorithm,
      instructions,
    } = action.payload;

    // Call API.
    const _params = {
      gh_requests: [
        {
          points,
          vehicle: vehicle || "motorcycle",
          request_id,
          calc_points: calc_points || true,
          points_encoded: points_encoded || false,
          instructions: instructions || true,
          locale: locale || "vi",
          algorithm,
        },
      ],
    };
    const res = yield call(API.getRouteRequest, { ..._params });
    yield put(updateRoute(res.data));
  } catch (err) {
    const _data = err.response && err.response.data;
    dispatchSnackbarError(_data);
  }
}

function* GetRouteSaga() {
  yield takeLatest(TYPES.GET_ROUTE, getRoute);
}

export { GetRouteSaga };
