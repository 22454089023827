import * as types from "./SuggestionTypes";

export const suggestionRequest = (payload) => ({
    type: types.GET_SUGGESTION_REQUEST,
    payload,
});

export const suggestionReceive = payload => ({
    type: types.GET_SUGGESTION_RECEIVE,
    payload
});

export const updateSuggestions = payload => ({
    type: types.UPDATE_SUGGESTION,
    payload
});
