import * as types from "./OperationType";

export const getRoute = (payload) => ({
  type: types.GET_ROUTE,
  payload,
});

export const updateRoute = (payload) => ({
  type: types.UPDATE_ROUTE,
  payload,
});

export const actionClear = (payload) => ({
  type: types.CLEAR,
  payload,
});
