import {
  OAuth2CallbackHandler,
  GhtkAuth,
  AuthRedirectGuardMiddleware,
} from "gmjs";
import oidc from "../configs/oidc";
import { axiosInstance } from "network/apis";

const getTokenId = () => {
  const accessTokenObj = JSON.parse(
    localStorage.getItem("ghtk-token-storage") || "{}"
  );
  const accessToken =
    Object.keys(accessTokenObj).length > 0
      ? accessTokenObj?.accessToken?.accessToken
      : null;

  return accessToken;
};

const isLogined = () => {
  const accessToken = getTokenId();
  return accessToken ? true : false;
};

const login = () => {
  try {
    const authRedirectGuardMiddleware = AuthRedirectGuardMiddleware(oidc);
    authRedirectGuardMiddleware.run("/", "/");
  } catch (e) {
    // Handle exception here
    logout();
  }
};

const callback = async () => {
  try {
    const handler = OAuth2CallbackHandler(oidc);
    if (await handler.handle()) {
      // Login successfully
      window.location = handler.getRefererPath() || "/";
    }
  } catch (e) {
    // Handle exception here
    logout();
  }
};

const logout = async () => {
  const redirectUri = `${window.location.protocol}//${window.location.host}`;
  try {
    const ghtkAuth = new GhtkAuth(oidc);
    await ghtkAuth.signOut({
      revokeAccessToken: true,
      postLogoutRedirectUri: `${oidc.authnUrl}/sign-out?continue=${redirectUri}`,
    });
  } catch (e) {
    window.location = `${oidc.authnUrl}/sign-out?continue=${redirectUri}`;
  }
};

export const getTokenGchat = () => {
  const tokenId = getTokenId();

  return axiosInstance.post(
    `${process.env.REACT_APP_GCHAT_APIURL}/api/v3/auth/access_token`,
    {
      type: "user",
      token: tokenId,
    },
    {
      headers: {
        accept: "application/json, text/plain, */*",
        "content-type": "application/json;charset=UTF-8",
        apptype: "web",
      },
    }
  );
};

const updateToken = (data) => {
  localStorage.setItem("token", data.access_token);
  localStorage.setItem("refresh_token", data.refresh_token);
};

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token") ?? "";
  // Call request to refresh token
  const res = await axiosInstance.post(
    `${process.env.REACT_APP_GCHAT_APIURL}/api/v3/auth/refresh_token`,
    {
      refresh_token: refreshToken,
    }
  );
  if (res?.data?.data?.access_token) {
    updateToken(res.data.data);
  }
};

const Auth = {
  login,
  logout,
  callback,
  isLogined,
  getTokenGchat,
  refreshToken,
};

export default Auth;
