import * as types from "./SuggestionTypes";

const INITIAL_STATE = {};

const SuggestionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_SUGGESTION_RECEIVE:
            return {
                ...state,
                ...action.payload,
                fetching: false
            };
        case types.UPDATE_SUGGESTION:
            return {
                ...state,
                ...action.payload,
                fetching: false
            };
        default:
            return {
                ...state,
                fetching: true
            };
    }
};

export default SuggestionReducer;
