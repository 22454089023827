import { isEmpty } from "lodash";
import { toTitleCase } from "./convertText";
import { convertTypePoint } from "./convertTypePoint";
import { converVietNamese } from "./converVietNamese";
import {
  CONFIG_TYPE_DEFAULT_IMAGE,
  CONFIG_TYPE_DETAIL,
  CONFIG_TYPE_IMAGE,
  NUMBER_GENERAL_INFO,
} from "./Constants";

export function convertDataPoints({
  items = [],
  optionFix = [],
  tabList = [],
}) {
  return items.map((element) =>
    convertDataPoint({ element, optionFix, tabList })
  );
}

export function convertDataPoint({ element, optionFix = [], tabList }) {
  // Additional option fix.
  optionFix.forEach((option) => {
    element[option.key] = option.value;
  });

  if (isEmpty(element)) {
    return element;
  }

  if (element.id) {
    // New form data
    element.point = {
      lat: Number(element?.point?.lat || element?.location?.lat),
      lng: Number(element?.point?.lng || element?.location?.lon),
    };

    let addressIcon = "";

    const extraDataList = element?.extra_informations || [];
    if (extraDataList) {
      const listImg = getImgDisplay(extraDataList, tabList);
      element.image_display = listImg;

      if (extraDataList[0] && extraDataList[0]?.type_code) {
        const typeCode = extraDataList[0]?.type_code;
        const addressType = tabList.find((tab) => tab?.code === typeCode);
        addressIcon =
          !isEmpty(addressType) && addressType?.icon ? addressType?.icon : "";

        element.type_code = typeCode;
      }

      if (extraDataList[0] && extraDataList[0]?.type_name) {
        element.type_name = extraDataList[0]?.type_name;
      }
    }

    element.detail =
      extraDataList &&
      extraDataList[0]?.details &&
      extraDataList[0]?.details[CONFIG_TYPE_DETAIL]
        ? extraDataList[0]?.details[CONFIG_TYPE_DETAIL]
        : "";

    element.icon = addressIcon;

    element.name = getPlaceName(element);

    let imUnitString = [];
    imUnitString = element?.im_units?.map((unit) => unit.name) || [];
    const fullAddress = element.full_name;

    element.full_name = toTitleCase(fullAddress);
    element.city = toTitleCase(element.lv1_name);
    element.district = toTitleCase(element.lv2_name);
    element.ward = toTitleCase(element.lv3_name);
    element.street = toTitleCase(imUnitString.join(", "));
  } else {
    // Old form data
    const details = element?.detail;

    let _nameView = "";
    let _nameAddress = "";
    let _name = "";

    details &&
      details.forEach((_i, _index) => {
        if (_index === 0) {
          _nameView = _i.name;
        }

        if (_index < details.length - 1 && _i.name) {
          _name += `${_i.name}, `;
          if (_index !== 0) {
            _nameAddress += `${_i.name}, `;
          }
        } else if (_index >= details.length - 1 && _i.name) {
          _name += _i.name;
          if (_index !== 0) {
            _nameAddress += `${_i.name}`;
          }
        }
      });

    _nameView = toTitleCase(_nameView);
    _nameAddress = toTitleCase(_nameAddress).trim();
    _name = converVietNamese(_name).toLowerCase().trim();

    element.nameView = _nameView ? _nameView : element.nameView;
    element.nameAddress = _nameAddress
      ? _nameAddress
      : element.nameAddress
      ? element.nameAddress
      : _nameView;
    element.nameVN = _name ? _name : element.nameVN;

    element.lat = Number(element.lat);
    element.lon = Number(element.lon);

    element.typeVN = element.typeVN
      ? toTitleCase(convertTypePoint(element.type, element.class))
      : "";
  }
  return element;
}

export function getPlaceName(element) {
  let display = element?.full_name || "";

  if (
    element?.extra_informations &&
    element?.extra_informations[0]?.address_name
  ) {
    display = element.extra_informations[0].address_name;
  } else if (element?.name) {
    display = element.name;
  } else if (element?.street && (element?.number || element?.detail)) {
    display = [element.number, element.detail, element.street]
      .filter((item) => item)
      .join(" ");
  }

  return toTitleCase(display);
}

export const getImgDisplay = (extraDataList, tabList) => {
  if (!extraDataList || !extraDataList.length) return [];

  return (
    extraDataList
      .map((extraData) => {
        if (extraData?.details) {
          let imgList = [];

          if (extraData?.details[CONFIG_TYPE_DEFAULT_IMAGE]) {
            imgList =
              extraData?.details[CONFIG_TYPE_DEFAULT_IMAGE].split(",") || [];
          } else {
            const tabItem = tabList?.find(
              (tab) => tab?.code === extraData?.typeCode
            );
            if (tabItem) {
              const attributeRequestList =
                tabItem?.attribute_request_list || [];

              imgList = (attributeRequestList.slice(NUMBER_GENERAL_INFO) || [])
                .filter((att) => att?.type_name === CONFIG_TYPE_IMAGE)
                .map((att) =>
                  extraData?.details[att?.name]
                    ? extraData?.details[att?.name].split(",")
                    : []
                );
            }
          }

          return imgList.length ? imgList[0] : "";
        }

        return "";
      })
      .find((itemImg) => !!itemImg) || ""
  );
};
// export function getPlaceDetailName(element) {
//   console.log({ element });
//   let display = element?.full_name || "";

//   if (element?.name) {
//     const prefix = element?.prefix ? element?.prefix + " " : "";
//     const suffix = element?.suffix ? element?.suffix + " " : "";
//     display = prefix + element.name + suffix;
//   }

//   return toTitleCase(display);
// }
