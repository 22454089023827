/* eslint-disable */
import React from "react";
import _ from "lodash";
import Auth from "../utils/auth";
import { Redirect, Route } from "react-router-dom";

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const children = this.props.children;

    return (
      <Route
        render={({ location }) =>
          Auth.isLogined() ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }
}

export default PrivateRoute;
