import React from "react";
import rtl from "jss-rtl";
import { create } from "jss";
import Auth from "utils/auth";
// Masterial
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { StylesProvider, jssPreset } from "@material-ui/styles";

// Component
import App from "./containers/App";

// import { useEffect } from "react";

import "maplibre-gl/dist/maplibre-gl.css";

function ThemeApp() {
  // useEffect(() => {
  //   getTokenGchat();
  // }, []);

  // const getTokenGchat = async () => {
  //   const result = await Auth.getTokenGchat();
  //   localStorage.setItem("refresh_token", result.data.data.refresh_token);
  //   localStorage.setItem("token", result.data.data.access_token);
  // };

  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#1976d2",
      },
      secondary: {
        main: "#ac4556",
      },
    },
  });

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StylesProvider>
  );
}

export default ThemeApp;
