const vi = {
  vi: {
    hello: "No",
    langBtn : "English",
    home: {
      content: 'Viet Nam'
    },
    snackbar: {
      'success' : 'Viett Nam',
      'copySuccess' : 'Đã sao chép địa chỉ!'
    }
  }
};

export default vi;
