/* eslint-disable */
import React from "react";
import { Router } from "react-router-dom";
import history from "../routes/History";
import Routes from "../routes/Routes";
import { IntlProvider } from "react-intl";
import messages from "../assets/Local/messages";
import { MaterialSnackbar } from "../components/Snackbar/Snackbar";
import Loader from "../components/Loader/Loader";
import "./App.scss";
import { connect } from "react-redux";
import { setCurrentLang } from "../store/Lang/LangAction";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

if (!String.prototype.trim) {
  String.prototype.trim = function () {
    return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
  };
}

const queryClient = new QueryClient();

Array.prototype.remove = function () {
  var what,
    a = arguments,
    L = a.length,
    ax;
  while (L && this.length) {
    what = a[--L];
    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1);
    }
  }
  return this;
};

class App extends React.Component {
  // App contains routes and also wrapped with snackbar and intl for localization

  componentDidMount() {
    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = process.env.REACT_APP_MATOMO_URL;
    s.parentNode.insertBefore(g, s);
  }

  render() {
    const { lang, loading } = this.props;
    return (
      <IntlProvider locale={lang} messages={messages[lang]}>
        <QueryClientProvider client={queryClient}>
          <div className={lang === "vi" ? "rtl" : "ltr"}>
            {loading ? <Loader /> : null}
            <Router history={history}>
              <Routes lang={lang} />
            </Router>
            <MaterialSnackbar />
          </div>
        </QueryClientProvider>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.lang,
  loading: state.loading,
});

export default connect(mapStateToProps, { setCurrentLang })(App);
