export const toTitleCase = (phrase = "") => {
  return phrase
    ? phrase
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : "";
};

export const formatInputTextSuggestion = (inputText = "") => {
  return inputText
    .replaceAll(", ", " ")
    .replaceAll(" ,", " ")
    .replaceAll(",", " ");
};
