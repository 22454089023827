import { call, put, select } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";
import _ from "lodash";
import { OPTIMIZE_OPTION, PROFILES } from "utils/Constants";

// API
import API from "./DirectActionApis";

// Action
import * as TYPES from "./DirectType";
import {
  actionUpdatePoint,
  actionUpdateRedirect,
  actionUpdateLoading,
} from "./DirectAction";
import { actionGetReverse } from "../Reverse/ReverseAction";

// Util
import { ID } from "../../utils/generateId";
import { dispatchSnackbarError } from "../../utils/Shared";

const getPoints = (state) => state.Direct.data;
const getProfile = (state) => state.Direct.profile;

const checkUpdate = (data) => {
  let isOK = true;
  data.forEach((item) => {
    if (_.isEmpty(item?.content?.data)) {
      isOK = false;
    }
  });
  return isOK;
};

// Update data Points
export function* sagasUiDirect01(action) {
  try {
    const profile = yield select(getProfile);
    const points = action.payload;
    const isOK = checkUpdate(points);
    if (isOK) {
      const locations = points.map((point) => [
        point?.content?.data?.point?.lng,
        point?.content?.data?.point?.lat,
      ]);
      yield put(actionUpdateLoading(true));

      // Call API.
      let response;
      if (profile === "optimal") {
        response = yield call(API.getDirectOptimal, {
          points: locations,
          instructions: true,
          points_encoded: true,
          optimize_option: OPTIMIZE_OPTION.DISTANCE,
          profiles: PROFILES,
          locale: "vi",
        });
      } else {
        let _params = {
          points: locations,
          locale: "vi",
          elevation: true,
          profile: profile || "car",
          instructions: true,
          points_encoded: true,
          snap_preventions: ["ferry"],
          algorithm: "alternative_route",
          details: [
            "road_class",
            "road_environment",
            "max_speed",
            "average_speed",
          ],
        };
        response = yield call(API.getDirectRequest, _params);
      }
      const _paths = response?.data?.data?.paths;

      _paths.forEach((item) => {
        item.instructions.forEach((_item) => {
          _item.id = ID();
        });
      });

      if (response.status === 200) {
        yield put(actionUpdateLoading(false));
        yield put(actionUpdateRedirect(response.data));
      }
    } else {
      // Bỏ qua.
    }
  } catch (err) {
    const _data = err.response && err.response.data;
    yield put(actionUpdateLoading(false));
    dispatchSnackbarError(_data);
  }
}

// Update data of point
export function* sagasUiDirect02(action) {
  try {
    const points = yield select(getPoints);
    const profile = yield select(getProfile);
    let { idInput, data, searchParam, isFocus } = action.payload;

    const _points = points.map((item) =>
      item.id === idInput
        ? { id: idInput, type: item.type, content: data, isFocus: isFocus }
        : item
    );

    const isOK = checkUpdate(_points);
    if (isOK) {
      const locations = points.map((point) => [
        point?.content?.data?.point?.lng,
        point?.content?.data?.point?.lat,
      ]);
      yield put(actionUpdateLoading(true));

      // Call API.
      let response;
      if (profile === "optimal") {
        response = yield call(API.getDirectOptimal, {
          points: locations,
          instructions: true,
          points_encoded: true,
          optimize_option: OPTIMIZE_OPTION.DISTANCE,
          profiles: PROFILES,
          locale: "vi",
        });
      } else {
        let _params = {
          points: locations,
          locale: "vi",
          elevation: true,
          profile: profile || "car",
          instructions: true,
          points_encoded: true,
          snap_preventions: ["ferry"],
          algorithm: "alternative_route",
          details: [
            "road_class",
            "road_environment",
            "max_speed",
            "average_speed",
          ],
        };
        response = yield call(API.getDirectRequest, _params);
      }
      const _paths = response?.data?.data?.paths;

      _paths.forEach((item) => {
        item.instructions.forEach((_item) => {
          _item.id = ID();
        });
      });

      if (response.status === 200) {
        yield put(actionUpdateLoading(false));
        yield put(actionUpdateRedirect(response.data));
      }
    } else {
      // Bỏ qua.
    }
  } catch (err) {
    const _data = err.response && err.response.data;
    yield put(actionUpdateLoading(false));
    dispatchSnackbarError(_data);
  }
}

function* sagaCallbackUpdate(payload) {
  const { data, idPoint } = payload;

  yield put(
    actionUpdatePoint({
      data: { data, type: "click" },
      idInput: idPoint,
    })
  );
}

export function* sagasUiDirectRevert(action) {
  try {
    // Start.
    const { data, type, id, isFocus } = action.payload;

    const points = yield select((state) => state.Direct.data);

    let point = points.find((item) => item.id === id);
    if (!point) {
      point = points.find((item) => item.type === type);
    }

    // yield put(
    //   actionUpdatePoint({
    //     data: { data: point?.content?.data, type: "click" },
    //     idInput: point.id,
    //     isFocus: isFocus,
    //   })
    // );

    // Tìm điểm tồn tại gần nhất trên map với điểm thay đổi để chỉ đường
    yield put(
      actionGetReverse({
        ...data,
        idPoint: point.id,
        callback: sagaCallbackUpdate,
      })
    );
  } catch (err) {
    const _data = err.response && err.response.data;
    dispatchSnackbarError(_data);
  }
}

export function* sagasUiDirectByType(action) {
  try {
    // Start.
    const { data, type, id, isFocus } = action.payload;
    const points = yield select((state) => state.Direct.data);

    let point = points.find((item) => item.id === id);
    if (!point) {
      point = points.find((item) => item.type === type);
    }

    yield put(actionUpdatePoint({ data, idInput: point.id, isFocus }));
  } catch (err) {
    const _data = err.response && err.response.data;
    dispatchSnackbarError(_data);
  }
}

function* DirectUiSaga01() {
  yield takeLatest(TYPES.UPDATE_POINTS, sagasUiDirect01);
}

function* DirectUiSaga02() {
  yield takeLatest(TYPES.UPDATE_POINT, sagasUiDirect02);
}

function* DirectUiSaga03() {
  yield takeLatest(TYPES.UPDATE_REVERT_POINT, sagasUiDirectRevert);
}

function* DirectUiSaga04() {
  yield takeLatest(TYPES.UPDATE_POINT_BY_TYPE, sagasUiDirectByType);
}

export { DirectUiSaga01, DirectUiSaga02, DirectUiSaga03, DirectUiSaga04 };
