import { combineReducers } from "redux";
import lang from "../Lang/LangReducer";
import loader from "../Loader/LoaderReducer";
import snackbar from "../Snackbar/SnackbarReducer";
import globalUi from "../GlobalUi/GlobalUiReducer";

import Suggestion from "../Suggestion/SuggestionReducer";
import Search from "../Search/SearchReducer";
import Direct from "../Direct/DirectReducer";
import AddressDetail from "../AddressDetail/AddressDetailReducer";
import Operation from "../Operation/OperationReducer";

export default combineReducers({
  lang,
  loader,
  snackbar,
  globalUi,
  Suggestion,
  Search,
  Direct,
  AddressDetail,
  Operation,
});
