import { call, put, select, takeLatest } from "redux-saga/effects";

// Type
import * as TYPES from "./GlobalUiType";

// Action
import {
  actionUpdatePage,
  actionUpdatePlace,
  actionUpdateStatusLeft,
  actionUpdateTabLeft,
  actionUpdateLayoutStatusLeft,
  actionUpdateAccessDataTracking,
  actionUpdatePlaceByUrl,
} from "./GlobalUiAction";
import { actionClear } from "store/Direct/DirectAction";
import { getLatLngFromUrl } from "../../utils/getLatLngFromUrl";
import History from "../../routes/History";
// import { searchDetailRequest } from "../Search/SearchAction";
import _ from "lodash";
import { actionSetAddressDetail } from "../AddressDetail/AddressDetailAction";
import {
  convertDataPoint,
  convertDataPoints,
} from "../../utils/convertDataPoint";
import API from "../Suggestion/SuggestionApis";
import { getSuggestionFromCache } from "../Suggestion/SubggestionSaga";
import * as ACTION_SUGGESTION from "../Suggestion/SuggestionAction";
import { generateTrackingData, sendTrackingDataList } from "utils/tracking";
import { actionTracking, typeTracking } from "utils/Constants";
import reverseAPI from "../Reverse/ReverseApis";
import isEmpty from "lodash/isEmpty";

// import * as ACTION_SEARCH from "../Search/SearchAction";

const getMaps = (state) => state.globalUi.map;
const getPage = (state) => state.globalUi.page;
const getAccessDataTrackingList = (state) => state.globalUi.accessDataTracking;
const getTabList = (state) => state.globalUi.tabList;

function* getPointByInfoWindow(addr_id) {
  let placeData = {};

  const res = yield call(reverseAPI.getPartnerInfoWindow, {
    address_id: addr_id,
  });

  const dataRes = res?.data?.data || {};

  if (dataRes && !isEmpty(dataRes)) {
    const full_data = dataRes?.full_data || {};
    if (full_data && !isEmpty(full_data)) {
      const pointClicked = {
        ...full_data,
        id: addr_id,
        addr_id: addr_id,
        extra_informations: full_data?.extra_information,
        point: {
          lat: full_data?.location?.lat || null,
          lng: full_data?.location?.lon || null,
        },
        lv1_id: dataRes?.level1_id,
        lv2_id: dataRes?.level2_id,
        lv3_id: dataRes?.level3_id,
        im_units: full_data?.imunits,
        feature_source: "partner-preview",
      };

      const tabList = yield select(getTabList);

      placeData = convertDataPoint({ element: pointClicked, tabList });
    }
  }

  return placeData;
}

function* findPlaceSagaWorker(action) {
  try {
    const { data, name, isNotFromUrl, isPartnerPreview } = action.payload;
    const dataString = decodeURIComponent(escape(atob(data)));

    const [lat, lng, id, fullAddress] = dataString.split("-");

    const dataObject = {
      id: Number(id),
      city: "",
      detail: "",
      district: "",
      full_name: fullAddress.replaceAll("+", " "),
      name: name,
      number: "",
      point: {
        lat: Number(lat),
        lng: Number(lng),
      },
      street: "",
      ward: "",
    };

    const isNotFromUrlData = isNotFromUrl ?? true;
    const isPreViewPartner = isPartnerPreview ?? false;

    if (!isNotFromUrlData) {
      yield put(
        actionUpdatePlaceByUrl({
          type: "click",
          removeTitle: true,
          showDetail: true,
          data: dataObject,
        })
      );
    } else if (isPreViewPartner) {
      const point = yield getPointByInfoWindow(id);

      if (point && !isEmpty(point)) {
        yield put(
          actionUpdatePlace({
            type: "click",
            data: point,
            showDetail: true,
            removeTitle: true,
          })
        );
      }
    } else {
      yield put(
        actionUpdatePlace({
          type: "click",
          removeTitle: true,
          showDetail: true,
          data: dataObject,
        })
      );
    }
  } catch (e) {
    console.log(e);
  }
}

export function* findPlaceSaga() {
  yield takeLatest(TYPES.FIND_PLACE, findPlaceSagaWorker);
}

function* updatePlaceSagaWorker(action) {
  try {
    const { data, showDetail = true } = action.payload;

    if (!_.isEmpty(data)) {
      const page = yield select((state) => state.globalUi.page);
      // Xác nhận chuyển sang giao diện detail
      if (showDetail) {
        if (page === "direct") {
          yield put(actionUpdatePage("home"));
        }
        yield put(actionUpdateTabLeft("Detail"));
      }

      yield put(actionUpdateStatusLeft("extend"));
      yield put(actionUpdateLayoutStatusLeft(true));
      let placeData = data;

      // get more info address
      if (data?.id) {
        const newPlaceData = yield getPointByInfoWindow(data?.id);
        if (newPlaceData && !isEmpty(newPlaceData)) {
          placeData = newPlaceData;
        }
      }

      yield put(actionSetAddressDetail(placeData));

      // Lấy thêm boudary nếu điểm chọn là boundary.
      // if (data.class === "boundary") {
      //   yield put(searchDetailRequest({ data }));
      // }

      // Đóng gói dữ liệu và mã hóa trên url.
      const lat = data.point.lat;
      const lng = data.point.lng;
      const id = data?.id;
      const name = data?.name?.replaceAll(" ", "+") || "";
      const fullAddress = data?.full_name?.replaceAll(" ", "+") || "";
      // const number = data?.number || "";

      if (lat && lng && showDetail) {
        const pathname = window.location.pathname;
        const index = pathname.indexOf("/@");
        const _data = pathname.substring(index, pathname.length);
        const latLocation = localStorage.getItem("latitudeLocation");
        const lngLocation = localStorage.getItem("longitudeLocation");

        const mapConfig = getLatLngFromUrl(_data, {
          latLocation,
          lngLocation,
        });

        const _path = `/place/${name || fullAddress}/data=${btoa(
          unescape(encodeURIComponent(`${lat}-${lng}-${id}-${fullAddress}`))
        )}/@${mapConfig.latitude},${mapConfig.longitude}`;
        // yield put(actionUpdateLayoutStatusLeft(true));
        History.push(_path);
      }

      updatePlaceHistoryLocal(data);

      if (showDetail) {
        // Call API nhận về suggestion.
        // Setup data
        let _maps = (yield select(getMaps)) || {
          zoom: 16,
          distanceX: 1,
          distanceY: 1,
          centerPoint: {
            latitude: 21.0293118043509,
            longitude: 105.7890007095356,
          },
          nePoint: {
            latitude: 21.01092370346716,
            longitude: 105.7717927957083,
          },
          swPoint: {
            latitude: 21.038843103129956,
            longitude: 105.82501275054034,
          },
        };
        const limit = 5;
        const mode = "around";

        const _centerPoint = _maps.centerPoint;
        const paramStringNear = {
          address: data?.full_name ? data.full_name : null,
          lat: _centerPoint.latitude,
          lng: _centerPoint.longitude,
          size: limit,
          mode: mode,
        };

        const responseNear = yield call(API.getSuggetionList, paramStringNear);
        const tabList = yield select(getTabList);

        // Làm sạch dữ liệu trước khi đẩy vào store.
        const dataNear = responseNear.data?.data?.addresses || [];
        const datsList = [...dataNear];
        const dataConvertList = convertDataPoints({ items: datsList, tabList });

        // Gom và ưu tiên cache.
        let caches = getSuggestionFromCache(data.full_name) || [];
        const _newData = [...caches, ...dataConvertList];

        // Filter dữ liệu trùng.
        const _osms = _newData.map((v) => {
          if (v.id) {
            return v.id;
          } else {
            return v.osm_id;
          }
        });
        const _osmFilter = [...new Set(_osms)];

        const __pointObjectNew = _newData.filter((item) => {
          if (item.id && _osmFilter.includes(item.id)) {
            _osmFilter.remove(item.id);
            return true;
          }

          return false;
        });
        yield put(
          ACTION_SUGGESTION.suggestionReceive({
            detail: __pointObjectNew.slice(0, 10),
          })
        );
      }
    } else {
      // TH Data Xoa diem.
      const { type } = action.payload;
      const page = yield select(getPage);
      if (type === "click" && page === "home") {
        // yield put(actionUpdateLayoutStatusLeft(false));
        const _pathname = window.location.pathname;
        const index = _pathname.indexOf("/@");
        const _data = _pathname.substring(index, _pathname.length);
        History.push(_data);
      }
    }
  } catch (e) {
    console.log(e);
  }
}

const updatePlaceHistoryLocal = (data) => {
  try {
    if (data.full_name) {
      // Cache lại lịch sử để sử dụng khi người dùng vào lại lần sau.
      // Nếu quá lịch sử lớn hơn 3 địa chỉ thì sẽ show.
      const _pointString = localStorage.getItem("history-search");
      const _pointObject = JSON.parse(_pointString) || [];
      const _pointObjectNew = [data, ..._pointObject];
      const idList = _pointObjectNew.map((item) => item.id);

      const idsFilter = [...new Set(idList)];
      const __pointObjectNew = _pointObjectNew.filter((item) => {
        if (idsFilter.includes(item.id)) {
          idsFilter.remove(item.id);
          return true;
        }
        return false;
      });

      const _pointObjectSlice = __pointObjectNew.slice(0, 500);
      localStorage.setItem("history-search", JSON.stringify(_pointObjectSlice));
    }
  } catch (error) {
    console.log(error);
  }
};

export function* updatePlaceSaga() {
  yield takeLatest(TYPES.UPDATE_PLACE, updatePlaceSagaWorker);
}

function* updatePlaceByUrl(action) {
  const { data } = action.payload;

  if (!_.isEmpty(data)) {
    const page = yield select((state) => state.globalUi.page);

    if (page === "direct") {
      yield put(actionUpdatePage("home"));
    }
    yield put(actionUpdateTabLeft("Detail"));

    yield put(actionUpdateStatusLeft("extend"));
    yield put(actionUpdateLayoutStatusLeft(true));
    yield put(actionSetAddressDetail(data));

    updatePlaceHistoryLocal(data);
  }
}

export function* updatePlaceByUrlSaga() {
  yield takeLatest(TYPES.UPDATE_PLACE_BY_URL, updatePlaceByUrl);
}

function* updatePageSagaWorker(action) {
  try {
    const page = action.payload;
    if (page === "home") {
      yield put(actionClear());
    }
  } catch (e) {
    console.log(e);
  }
}

export function* updatePageSaga() {
  yield takeLatest(TYPES.UPDATE_PAGE, updatePageSagaWorker);
}

function* sendListTracking(action) {
  try {
    const { buttonTrack, actionTrack, errorTrack } = action.payload;

    const accessDataTrackingList = yield select(getAccessDataTrackingList);

    const data = [];

    if (!accessDataTrackingList.length) {
      const startAccessDataTracking = generateTrackingData({
        type: typeTracking.info,
        action: actionTracking.view,
        start: true,
      });

      yield put(actionUpdateAccessDataTracking([startAccessDataTracking]));

      data.push(startAccessDataTracking);
    }

    data.push(
      generateTrackingData({
        type: typeTracking.info,
        action: actionTrack || actionTracking.click,
        buttonTrack,
      })
    );

    if (errorTrack) {
      const errorDataTracking = generateTrackingData({
        type: typeTracking.error,
        action: actionTracking.crash,
        error: errorTrack,
      });

      data.push(errorDataTracking);
    }

    sendTrackingDataList(data);
  } catch (error) {
    console.log(error);
  }
}

export function* sendDataTrackingSaga() {
  yield takeLatest(TYPES.SEND_DATA_TRACKING, sendListTracking);
}
