import _ from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";

// Api.
import API from "./SearchApis";

// Action
import * as ACTION_SEARCH from "./SearchAction";
import * as ACTION_GLOBAL from "../GlobalUi/GlobalUiAction";

// Type
import * as TYPES from "./SearchTypes";

// Util
import { dispatchSnackbarError } from "../../utils/Shared";
import { converVietNamese } from "../../utils/converVietNamese";
import { searchRequest } from "./SearchAction";
import { ID } from "../../utils/generateId";
import { toTitleCase } from "../../utils/convertText";
import { actionUpdatePoint } from "../Direct/DirectAction";
import { actionUpdateLoadingDirect } from "../GlobalUi/GlobalUiAction";
import {
  convertDataPoint,
  convertDataPoints,
} from "../../utils/convertDataPoint";

// Select
const getMaps = (state) => state.globalUi.map;
const getPlace = (state) => state.globalUi.place;
const getCurrentLocation = (state) => state.globalUi.currentLocation;
const getTabList = (state) => state.globalUi.tabList;

export function* sagasRequestSearch(action) {
  try {
    // Nhập param search.
    const { textInput, typeAddress } = action.payload;

    yield put(ACTION_GLOBAL.actionUpdatePlace({ type: "click", data: {} }));
    yield put(
      ACTION_SEARCH.searchLoading({ searchByText: { isLoading: true } })
    );

    const _maps = yield select(getMaps);
    const currentLocation = yield select(getCurrentLocation);
    const tabList = yield select(getTabList);

    const _centerPoint = _maps.centerPoint;

    const paramStringNear = {
      address: textInput ? textInput : null,
      address_type: typeAddress || null,
      lat: currentLocation?.lat || _centerPoint.latitude,
      lng: currentLocation?.lng || _centerPoint.longitude,
      size: 50,
    };

    const responseNear = yield call(API.getSearchListByText, paramStringNear);

    const dataNear = responseNear.data?.data?.addresses || [];
    const datsList = [...dataNear];

    // Làm sạch dữ liệu trước khi đẩy vào store.
    const dataConvertList = convertDataPoints({ items: datsList, tabList });
    const newData = [...dataConvertList];

    // Filter dữ liệu trùng.
    const ids = newData.map((v) => v.id);
    const idFilter = [...new Set(ids)];
    const pointObjectNew = newData.filter((item) => {
      if (item.id && idFilter.includes(item.id)) {
        idFilter.remove(item.id);
        return true;
      }
      return false;
    });

    yield put(
      ACTION_SEARCH.searchLoading({ searchByText: { isLoading: false } })
    );

    yield put(ACTION_SEARCH.searchReceive(pointObjectNew));
    yield put(ACTION_GLOBAL.actionUpdateTabLeft("Search"));
    yield put(ACTION_GLOBAL.actionUpdateLayoutStatusLeft(true));
    yield put(ACTION_GLOBAL.actionUpdateStatusLeft("extend"));

    // const _paramStringNear = `?text=${textInput}&lat=${_centerPoint.latitude}&lon=${_centerPoint.longitude}&tr_lat=${_nePoint.latitude}&tr_lon=${_nePoint.longitude}&bl_lat=${_swPoint.latitude}&bl_lon=${_swPoint.longitude}&mode=around&size=20`;
    // const _paramString = `?text=${textInput}&lat=${_centerPoint.latitude}&lon=${_centerPoint.longitude}&tr_lat=${_nePoint.latitude}&tr_lon=${_nePoint.longitude}&bl_lat=${_swPoint.latitude}&bl_lon=${_swPoint.longitude}&mode=normal&size=20`;
    // yield put(ACTION_GLOBAL.actionUpdatePlace({ type: "click", data: {} }));
    // const responseNear = yield call(API.getSearchRequest, _paramStringNear);

    // let _dataFull = [];
    // if (_dataNear.length === 0) {
    //   const response = yield call(API.getSearchRequest, _paramString);
    //   _dataFull = response.data || [];
    // }
    // // Làm sạch dữ liệu trước khi đẩy vào store.
    // const _data = [..._dataNear, ..._dataFull];
    // const _dataObject = _data.map((_item, __index) => {
    //   const _details = _item.detail;
    //   let _name = "";
    //   _details.forEach((_i, _index) => {
    //     if (_index < _details.length - 1 && _i.name) {
    //       _name += `${_i.name}, `;
    //     } else if (_index >= _details.length - 1 && _i.name) {
    //       _name += _i.name;
    //     }
    //   });
    //   _item.nameView = toTitleCase(_name);
    //   _item.nameVN = converVietNamese(_name).toLowerCase().trim();
    //   return _item;
    // });
    // const _osms = _dataObject.map((v) => v.osm_id);
    // const _osmFilter = [...new Set(_osms)];
    // const __pointObjectNew = _dataObject.filter((item) => {
    //   if (_osmFilter.includes(item.osm_id)) {
    //     _osmFilter.remove(item.osm_id);
    //     return true;
    //   }
    //   return false;
    // });
    // yield put(
    //   ACTION_SEARCH.searchLoading({ searchByText: { isLoading: false } })
    // );
    // yield put(ACTION_SEARCH.searchReceive(convertDataPoints(__pointObjectNew)));
  } catch (err) {
    const _data = err.response && err.response.data;
    dispatchSnackbarError(_data);
  }
}

export function* SearchSaga() {
  yield takeLatest(TYPES.GET_SEARCH_REQUEST, sagasRequestSearch);
}

export function* sagasRequestSearchByText(action) {
  try {
    const { textInput, typeAddress } = action.payload;

    // 1. Tìm kiếm theo API suggestion.
    // const _paramSuggestString = `?text=${textInput}`;
    // yield put(ACTION_GLOBAL.actionUpdatePlace({ type: "click", data: {} }));
    // yield put(
    //   ACTION_SEARCH.searchLoading({ searchByText: { isLoading: true } })
    // );

    // TODO NamVH: Bỏ tạm kịch bản search text to object Text
    // const responseSuggest = yield call(API.getSearchRequest, _paramSuggestString);
    // Làm sạch dữ liệu trước khi đẩy vào store.
    // const _dataSuggest = responseSuggest.data;
    // const _dataSuggestObject = _dataSuggest.map((_item, __index) => {
    //     const _details = _item.detail;
    //     let _name = '';
    //     _details.forEach((_i, _index) => {
    //         if (_index < _details.length - 1 && _i.name) {
    //             _name += `${_i.name}, `;
    //         } else if (_index >= _details.length - 1 && _i.name) {
    //             _name += _i.name;
    //         }
    //     });
    //
    //     _item.nameView = _name;
    //     _item.nameVN = converVietNamese(_name).toLowerCase().trim();
    //     return _item;
    // });
    // if (_dataSuggestObject.length > 0) {
    //     // Có dữ liệu. => Focus
    //     yield put(ACTION_GLOBAL.actionUpdatePlace({type: 'click', data: _dataSuggestObject[0]}));
    // }
    // Call 2 luồng => 1, suggestion quanh đây + 2, search text
    // Nhập param search.
    // const _paramSearchString = `?text=${textInput}&top=${30}`;
    // const response = yield call(API.getSearchByTextRequest, _paramSearchString);
    // Làm sạch dữ liệu trước khi đẩy vào store.
    // const _dataObjectSearch =  response.data.map((item) => {
    //     item.id = ID();
    //     return item;
    // });
    // yield put(ACTION_SEARCH.searchByTextReceive(_dataObjectSearch));

    yield put(searchRequest({ textInput, typeAddress }));
  } catch (err) {
    const _data = err.response && err.response.data;
    dispatchSnackbarError(_data);
  }
}

// Search By Text
export function* SearchByTextSaga() {
  yield takeLatest(TYPES.GET_SEARCH_BY_TEXT_REQUEST, sagasRequestSearchByText);
}

export function* sagasRequestSearchLatLong(action) {
  const { data: item, callback } = action.payload;
  try {
    // 1. Tìm kiếm theo API subgestion.
    const _paramSuggestString = `?province=${item.province}&district=${item.district}&ward=${item.ward}&street=${item.street}&house_number=&failover=1`;

    const response = yield call(
      API.getSearchLatLongRequest,
      _paramSuggestString
    );

    callback(response);
    // Làm sạch dữ liệu trước khi đẩy vào store.
    const type = response.data.from_provider || "";
    const tabList = yield select(getTabList);

    let _point = {};

    if (type === "google_api") {
      const _item = response.data.detail[0];
      const _detailString = _item.formatted_address.split(",");
      const _nameVN = converVietNamese(_item.formatted_address)
        .toLowerCase()
        .trim();

      _point = {
        osm_id: ID(),
        name: converVietNamese(_item.formatted_address.replace(",", " ")),
        nameView: toTitleCase(_item.formatted_address),
        detail: _detailString.map((_item) => {
          return {
            name: _item.trim(),
          };
        }),
        lat: _item.geometry.point.lat,
        lon: _item.geometry.point.lng,
        nameVN: _nameVN,
      };
    } else if (type === "nominatim_ex") {
      const _item = response.data.detail;
      const fullNameAddress = `${_item.name}, ${_item.address.street}, ${_item.address.city}, ${_item.address.province}`;
      const _nameVN = converVietNamese(_item.name).toLowerCase().trim();
      _point = {
        osm_id: _item.osm_id,
        name: converVietNamese(fullNameAddress.replace(",", " ")),
        nameView: toTitleCase(fullNameAddress),
        detail: [
          { name: _item.name },
          { name: _item.address.ward },
          { name: _item.address.street },
          { name: _item.address.city },
          { name: _item.address.province },
        ],
        lat: _item.lat,
        lon: _item.lng,
        nameVN: _nameVN,
      };
    } else {
      const _item = response.data.detail;
      const _detailString = _item.display_name.split(",");
      const _nameVN = converVietNamese(_item.display_name).toLowerCase().trim();
      _point = {
        osm_id: _item.osm_id,
        name: converVietNamese(_item.display_name.replace(",", " ")),
        nameView: toTitleCase(_item.display_name),
        detail: _detailString.map((_item) => {
          return {
            name: _item.trim(),
          };
        }),
        lat: _item.lat,
        lon: _item.lon,
        nameVN: _nameVN,
      };
    }

    // yield put(ACTION_SEARCH.addSearchReceive({[item.id]: _data}));

    yield put(
      ACTION_GLOBAL.actionUpdatePlace({
        type: "click",
        data: convertDataPoint({ element: _point, tabList }),
      })
    );
  } catch (err) {
    callback(err);
    const _data = err.response && err.response.data;
    dispatchSnackbarError(_data);
  }
}

// Search By Text
export function* SearchLatLongSaga() {
  yield takeLatest(
    TYPES.GET_SEARCH_LAT_LONG_REQUEST,
    sagasRequestSearchLatLong
  );
}

let cacheItemNull = [];

export function* sagasRequestSearchDetail(action) {
  try {
    const { data: item } = action.payload;

    // Nếu chưa có boudary thì callAPI để lấy. cache lại các điểm không lấy được boudary.
    if (
      (_.isEmpty(item.geometry) || !item?.geometry?.coordinates) &&
      !cacheItemNull.includes(item.osm_id)
    ) {
      // 1. Tìm kiếm theo API subgestion.
      const _paramSuggestString = `?osmtype=${item.osm_type}&osmid=${item.osm_id}&class=${item.class}&polygon_geojson=1&format=json`;

      const response = yield call(
        API.getSearchDetailRequest,
        _paramSuggestString
      );

      const _geometry = response.data.geometry || [];
      // Cache lại các trường hợp là boudary nhưng geometry rỗng.
      if (_geometry.length === 0) {
        cacheItemNull.push(item.item.osm_id);
      }

      const objectPlace = yield select(getPlace);
      objectPlace.data = { ...objectPlace.data, geometry: _geometry };

      if (objectPlace.data.osm_id === item.osm_id) {
        yield put(
          ACTION_GLOBAL.actionUpdatePlace({
            type: objectPlace.type,
            data: { ...objectPlace.data },
          })
        );
      }
    }
  } catch (err) {
    const _data = err.response && err.response.data;
    dispatchSnackbarError(_data);
  }
}

// Search detail
export function* SearchDetailSaga() {
  yield takeLatest(TYPES.GET_SEARCH_DETAIL_REQUEST, sagasRequestSearchDetail);
}

export function* searchByTextAutoCompleteWorker(action) {
  const { data, idInput } = action.payload;
  // Nhập param search.
  const _paramSearchString = `?address=${data}&top=${5}`;
  const responseSearch = yield call(
    API.getSearchByTextRequest,
    _paramSearchString
  );
  const firstData =
    responseSearch.data.length > 0 ? responseSearch.data[0] : {};

  // 1. Tìm kiếm theo API subgestion.
  const _paramSuggestString = `?province=${firstData.province}&district=${firstData.district}&ward=${firstData.ward}&street=${firstData.street}&house_number=${firstData.house_number}&street=${firstData.street}&failover=1`;

  const response = yield call(API.getSearchLatLongRequest, _paramSuggestString);
  const tabList = yield select(getTabList);

  // Làm sạch dữ liệu trước khi đẩy vào store.
  const type = response.data.from_provider || "";

  let _point = {};

  if (type === "google_api") {
    const _item = response.data.detail[0];
    const _detailString = _item.formatted_address.split(",");
    const _nameVN = converVietNamese(_item.formatted_address)
      .toLowerCase()
      .trim();

    _point = {
      osm_id: ID(),
      name: converVietNamese(_item.formatted_address.replace(",", " ")),
      nameView: toTitleCase(_item.formatted_address),
      detail: _detailString.map((_item) => {
        return {
          name: _item.trim(),
        };
      }),
      lat: _item.geometry.point.lat,
      lon: _item.geometry.point.lng,
      nameVN: _nameVN,
    };
  } else if (type === "nominatim_ex") {
    const _item = response.data.detail;
    const fullNameAddress = `${_item.name}, ${_item.address.street}, ${_item.address.city}, ${_item.address.province}`;
    const _nameVN = converVietNamese(_item.name).toLowerCase().trim();
    _point = {
      osm_id: _item.osm_id,
      name: converVietNamese(fullNameAddress.replace(",", " ")),
      nameView: toTitleCase(fullNameAddress),
      detail: [
        { name: _item.name },
        { name: _item.address.ward },
        { name: _item.address.street },
        { name: _item.address.city },
        { name: _item.address.province },
      ],
      lat: _item.lat,
      lon: _item.lng,
      nameVN: _nameVN,
    };
  } else {
    const _item = response.data.detail;
    const _detailString = _item.display_name.split(",");
    const _nameVN = converVietNamese(_item.display_name).toLowerCase().trim();
    _point = {
      osm_id: _item.osm_id,
      name: converVietNamese(_item.display_name.replace(",", " ")),
      nameView: toTitleCase(_item.display_name),
      detail: _detailString.map((_item) => {
        return {
          name: _item.trim(),
        };
      }),
      lat: _item.lat,
      lon: _item.lon,
      nameVN: _nameVN,
    };
  }

  yield put(actionUpdateLoadingDirect({ loading: false }));
  yield put(
    actionUpdatePoint({
      data: { data: convertDataPoint({ element: _point, tabList }) },
      idInput,
    })
  );
}

// Search and Auto Complete.
export function* searchByTextAutoCompleteSaga() {
  yield takeLatest(
    TYPES.GET_SEARCH_BY_TEXT_AUTO_COMPLETE,
    searchByTextAutoCompleteWorker
  );
}
