import { geoInstance } from "../../network/apis";
import { API_KEY_ROUTING } from "../../utils/Constants";

const headers = {
  "Content-Type": "application/json",
  apikey: API_KEY_ROUTING,
};

const getDirectRequest = async (_params) => {
  return await geoInstance.post(`/routes/v1/direction?`, _params, {
    headers,
  });
};

const getDirectOptimal = async (_params) => {
  return await geoInstance.post(`/routes/v1/route/shortest`, _params, {
    headers,
  });
};

const DirectActionApis = {
  getDirectRequest,
  getDirectOptimal,
};

export default DirectActionApis;
