import React from "react";

export const Home = React.lazy(() => import("../containers/Nav/NavContainer"));

export const Direct = React.lazy(() =>
  import("../containers/Direct/DirectContainer")
);

export const Login = React.lazy(() =>
  import("../containers/Login/LoginContainer")
);

export const Callback = React.lazy(() =>
  import("../containers/Callback/CallbackContainer")
);

export const Map = React.lazy(() => import("../containers/Map/MapContainer"));

export const MapPartner = React.lazy(() =>
  import("../containers/MapPartner/MapPartnerContainer")
);
