import { call, put, select, takeLatest } from "redux-saga/effects";
import * as types from "./AddressDetailTypes";

// Api.
import { getAddressDetailRequest } from "./AddressDetailApis";
import { actionSetAddressDetail } from "./AddressDetailAction";
import { dispatchSnackbarError } from "../../utils/Shared";

const getPlace = (state) => state.globalUi.place;

export function* sagasRequestAddressDetail(action) {
  try {
    const item = action.payload;

    const _paramSuggestString = `?osmtype=${item.osm_type}&osmid=${item.osm_id}&class=${item.class}&polygon_geojson=1&format=json`;

    const response = yield call(getAddressDetailRequest, _paramSuggestString);

    const _geometry = response.data.geometry;

    const objectPlace = yield select(getPlace);
    objectPlace.data = { ...objectPlace.data, geometry: _geometry };

    if (`${objectPlace.data.osm_id}` === `${item.osm_id}`) {
      yield put(actionSetAddressDetail(objectPlace.data));
    }
  } catch (err) {
    const _data = err.response && err.response.data;
    dispatchSnackbarError(_data);
  }
}

// Search detail
export function* GetAddressDetailSaga() {
  yield takeLatest(types.GET_ADDRESS_DETAIL, sagasRequestAddressDetail);
}
