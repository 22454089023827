const types = {
  station: "trạm",
  arts_centre: "Trung tâm Nghệ thuật",
  bank: "ngân hàng",
  bar: "quán bar",
  bbq: "BBQ.",
  bicycle_parking: "bãi đỗ xe đạp",
  bicycle_rental: "cho thuê xe đạp",
  biergarten: "quán bia ngoài tời",
  bus_station: "trạm xe buýt",
  cafe: "quán cà phê",
  cinema: "Rạp chiếu phim",
  clinic: "phòng khám bệnh",
  college: "trường Cao đẳng",
  community_centre: "Trung tâm cộng đồng",
  courthouse: "toà án",
  dentist: "bác sĩ nha khoa",
  doctors: "bác sĩ",
  drinking_water: "uống nước",
  fast_food: "thức ăn nhanh",
  ferry_terminal: "bến phà",
  fire_station: "trạm cứu hỏa",
  food_court: "khu ăn uống",
  fuel: "nhiên liệu",
  grave_yard: "nghĩa trang",
  hospital: "bệnh viện",
  ice_cream: "kem",
  kindergarten: "Mẫu giáo",
  library: "thư viện",
  marketplace: "chợ",
  motorcycle_parking: "điểm đỗ xe máy",
  nightclub: "câu lạc bộ đêm",
  nursing_home: "viện dưỡng lão",
  parking: "bãi đậu xe",
  pharmacy: "tiệm thuốc",
  place_of_worship: "Nơi thờ phụng",
  police: "sở cảnh sát",
  post_box: "hộp thư",
  post_office: "Bưu điện",
  prison: "nhà tù",
  pub: "quán pub",
  public_building: "toà nhà công cộng",
  recycling: "tái chế",
  restaurant: "quán ăn",
  school: "trường học",
  shelter: "nơi ở",
  swimming_pool: "Hồ bơi",
  taxi: "taxi",
  telephone: "Điện thoại",
  theatre: "rạp hát",
  toilets: "Nhà vệ sinh",
  townhall: "toà thị chính",
  university: "trường đại học",
  veterinary: "thú y",
  waste_basket: "thùng rác",
  bollard: "trụ an ninh",
  border_control: "kiểm soát biên giới",
  cycle_barrier: "rào chắn xe đạp",
  gate: "cánh cổng",
  lift_gate: "cửa thang máy",
  sally_port: "cổng thành",
  stile: "thang gỗ",
  toll_booth: "trạm thu phí",
  dormitory: "Ký túc xá.",
  bus_stop: "điểm dừng xe buýt",
  monument: "Đài kỷ niệm",
  castle: "lâu đài",
  ruins: "tàn tích",
  basin: "bồn rửa mặt",
  brownfield: "khu vực nhà xưởng bỏ trống",
  cemetery: "Nghĩa trang",
  reservoir: "Hồ chứa",
  winter_sports: "thể thao mùa đông",
  dog_park: "công viên cho chó",
  escape_game: "trò chơi lối thoát ",
  garden: "sân vườn",
  golf_course: "sân gôn",
  ice_rink: "sân băng",
  hackerspace: "khu vực sử dụng máy tính",
  marina: "bến du thuyền",
  miniature_golf: "sân gôn mini",
  park: "công viên",
  pitch: "sân bóng đá",
  playground: "sân chơi",
  sports_centre: "Trung tâm thể thao",
  stadium: "sân vận động",
  swimming_area: "khu bơi lội",
  water_park: "công viên nước",
  diplomatic: "ngoại giao.",
  halt: "tạm dừng",
  subway_entrance: "lối vào tàu điện ngầm",
  train_station_entrance: "lối vào trạm tàu ",
  tram_stop: "bến xe bus điện",
  accessories: "phụ kiện",
  alcohol: "rượu",
  antiques: "cổ vật",
  art: "nghệ thuật",
  bag: "túi xách",
  bakery: "cửa hàng bánh mì",
  beauty: "làm đẹp",
  bed: "Giường",
  beverages: "đồ uống",
  bicycle: "Xe đạp",
  books: "sách",
  boutique: "cửa hàng",
  butcher: "thịt",
  camera: "Máy ảnh",
  car: "xe ô tô",
  car_repair: "sửa chữa ô tô",
  car_parts: "phụ tùng xe hơi",
  carpet: "thảm",
  charity: "từ thiện",
  chemist: "Nhà hóa học.",
  chocolate: "sô cô la",
  clothes: "quần áo",
  coffee: "cà phê",
  computer: "máy vi tính",
  confectionery: "Bánh kẹo",
  convenience: "cửa hàng tiện lợi",
  copyshop: "cửa hàng photo",
  cosmetics: "mỹ phẩm.",
  deli: "Deli.",
  delicatessen: "Đặc sản",
  department_store: "cửa hàng bách hóa",
  doityourself: "cửa hàng đồ tự làm ",
  dry_cleaning: "giặt khô",
  electronics: "thiết bị điện tử",
  erotic: "khiêu dâm",
  fabric: "sợi vải",
  florist: "người bán hoa",
  frozen_food: "thức ăn đông lạnh",
  furniture: "đồ nội thất",
  garden_centre: "vườn trung tâm",
  general: "Tổng quan",
  gift: "quà tặng",
  greengrocer: "người bán rau quả",
  hairdresser: "thợ cắt tóc",
  hardware: "phần cứng",
  hearing_aids: "trợ thính",
  hifi: "thiết bị loa đài",
  interior_decoration: "trang trí nội thất",
  jewelry: "trang sức",
  kiosk: "ki ốt",
  lamps: "đèn",
  laundry: "giặt ủi",
  mall: "Trung tâm mua sắm",
  massage: "Mát xa",
  mobile_phone: "điện thoại di động",
  motorcycle: "xe máy",
  music: "Âm nhạc",
  musical_instrument: "nhạc cụ",
  newsagent: "sạp báo",
  optician: "Chuyên gia nhãn khoa",
  outdoor: "ngoài trời",
  perfume: "nước hoa",
  perfumery: "nước hoa",
  pet: "thú cưng",
  photo: "ảnh",
  second_hand: "hàng đã qua sử dụng",
  shoes: "giày",
  sports: "các môn thể thao",
  stationery: "Văn phòng phẩm",
  supermarket: "siêu thị",
  tailor: "nhà may",
  tattoo: "xăm hình",
  ticket: "vé",
  tobacco: "thuốc lá.",
  toys: "đồ chơi",
  travel_agency: "đại lý du lịch",
  video: "băng hình",
  video_games: "trò chơi điện tử",
  watches: "xem",
  weapons: "vũ khí",
  wholesale: "cửa hàng bán lẻ",
  wine: "rượu",
  american_football: "bóng đá Mỹ",
  archery: "bắn cung.",
  athletics: "Thế vận hội",
  australian_football: "bóng đá Úc",
  badminton: "cầu lông",
  baseball: "bóng chày",
  basketball: "bóng rổ",
  beachvolleyball: "bóng chuyền bãi biển",
  billiards: "Bi-a.",
  bmx: "bmx",
  boules: "nhảy",
  bowls: "bát.",
  boxing: "quyền anh",
  canadian_football: "bóng đá người Canada",
  canoe: "ca nô.",
  chess: "cờ vua",
  climbing: "leo",
  climbing_adventure: "phiêu lưu leo núi",
  cricket: "bóng chày",
  cricket_nets: "bóng chày lưới",
  croquet: "chơi cầu",
  curling: "bi đá trên băng",
  cycling: "Đi xe đạp",
  disc_golf: "Đĩa gôn",
  diving: "Lặn",
  dog_racing: "cuộc đua chó",
  equestrian: "Cưỡi ngựa",
  fatsal: "chất béo",
  field_hockey: "khúc côn cầu",
  free_flying: "bay tự do ",
  gaelic_games: "trò bóng ném",
  golf: "golf",
  gymnastics: "thể dục",
  handball: "bóng ném",
  hockey: "khúc côn cầu",
  horse_racing: "đua ngựa",
  horseshoes: "móng ngựa",
  ice_hockey: "khúc côn cầu trên băng",
  ice_stock: "kho đá lạnh",
  judo: "võ Judo",
  karting: "đường đua xe",
  korfball: "bóng chuyền",
  long_jump: "nhảy xa",
  model_aerodrome: "mô hình sân bay ",
  motocross: "xe mô tô",
  motor: "động cơ",
  multi: "",
  netball: "bóng lưới",
  orienteering: "định hướng.",
  paddle_tennis: "sân tennis",
  paintball: "bóng sơn",
  paragliding: "dù lượn",
  pelota: "pelota.",
  racquet: "vợt",
  rc_car: "xe điều khiển từ xa",
  rowing: "chèo thuyền",
  rugby: "bóng bầu dục",
  rugby_league: "liên đoàn bóng bầu dục",
  rugby_union: "đoàn bóng bầu dục",
  running: "chạy",
  sailing: "đi thuyền",
  scuba_diving: "môn lặn",
  shooting: "chụp",
  shooting_range: "trường bắn",
  skateboard: "ván trượt",
  skating: "trượt băng",
  skiing: "trượt tuyết",
  soccer: "bóng đá",
  surfing: "lướt sóng",
  swimming: "bơi lội",
  table_soccer: "Bàn bóng đá",
  table_tennis: "bóng bàn",
  team_handball: "đội bóng ném",
  tennis: "bóng bàn",
  toboggan: "xe trượt băng",
  volleyball: "bóng chuyền",
  water_ski: "trượt ván nước",
  yoga: "Yoga.",
  alpine_hut: "lều trên núi ",
  aquarium: "bể nuôi cá",
  artwork: "Tác phẩm nghệ thuật",
  attraction: "điểm du lịch",
  bed_and_breakfast: "nhà nghỉ phục vụ bữa sáng",
  camp_site: "khu cắm trại",
  caravan_site: "khu cắm trại bằng xe hơi",
  chalet: "Nhà gỗ.",
  gallery: "bộ sưu tập",
  guest_house: "nhà khách",
  hostel: "nhà trọ",
  hotel: "khách sạn",
  information: "thông tin",
  motel: "nhà nghỉ ",
  museum: "bảo tàng",
  picnic_site: "địa điểm dã ngoại",
  theme_park: "công viên giải trí",
  viewpoint: "điểm tham quan",
  zoo: "Vườn bách thú",
  dock: "Bến tàu",
  house: "Nhà",
  place: "Địa điểm",
};

export function convertTypePoint(type = "undefined", _class = "place") {
  return types[`${type}`] || types[`${_class}`];
}
