import { platformInfo } from "utils/Constants";
const isLatitude = (num) => isFinite(num) && Math.abs(num) <= 90;
const isLongitude = (num) => isFinite(num) && Math.abs(num) <= 180;

export const isValidLatLng = (lat, lng) => {
  return isLatitude(lat) && isLongitude(lng);
};

export const isValidLatLngText = (text = "") => {
  const [lat, lng] = text.split(",");
  const latValue = lat?.trim();
  const lngValue = lng?.trim();

  if (isValidLatLng(latValue, lngValue)) return [latValue, lngValue];
  return null;
};

export function distanceFromTwoCoordinates(lat1, lon1, lat2, lon2) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) dist = 1;
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    return dist * 1.609344;
  }
}

export function getNameView(item) {
  let name = "";
  let address = "";

  let nameView = item?.nameView?.split(",")?.map((i) => i.trim());

  if (nameView) {
    address = nameView.slice(-3).join(", ");
    name = item.nameView.replace(", " + address, "");
  }

  return { name, address };
}

// Trả về bound chứa tất cả các tọa độ
// var coordinates = [
//   [long, lat],
// ];
export const getLngLatBounds = (points) => {
  const bounds = {
    minLng: points[0][0],
    minLat: points[0][1],
    maxLng: points[0][0],
    maxLat: points[0][1],
  };
  points.forEach((coord) => {
    bounds.minLng = Math.min(bounds.minLng, coord[0]);
    bounds.minLat = Math.min(bounds.minLat, coord[1]);
    bounds.maxLng = Math.max(bounds.maxLng, coord[0]);
    bounds.maxLat = Math.max(bounds.maxLat, coord[1]);
  });
  return bounds;
};

export function getOSAndVersion() {
  const userAgent = navigator.userAgent || navigator.vendor;
  let os = "unknown";
  let version = "";

  if (/windows phone/i.test(userAgent)) {
    os = "Windows Phone";
  } else if (/win/i.test(userAgent)) {
    os = "Windows";
    const match = userAgent.match(/Windows NT (\d+\.\d+)/);
    if (match) version = match[1];
  } else if (/android/i.test(userAgent)) {
    os = "Android";
    const match = userAgent.match(/Android\s+([\d.]+)/);
    if (match) version = match[1];
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    os = "iOS";
    const match = userAgent.match(/OS\s([\d_]+)/);
    if (match) version = match[1].replace(/_/g, ".");
  } else if (/mac/i.test(userAgent)) {
    os = "macOS";
    const match = userAgent.match(/Mac OS X\s+([\d_]+)/);
    if (match) version = match[1].replace(/_/g, ".");
  } else if (/linux/i.test(userAgent)) {
    os = "Linux";
  }

  return { os, version };
}

export const getPlatformInfo = () => {
  const browserInfo = navigator.userAgent;

  let browser = platformInfo.anonymous;

  if (browserInfo.includes("Opera") || browserInfo.includes("Opr")) {
    browser = platformInfo.opera;
  } else if (browserInfo.includes("Edg")) {
    browser = platformInfo.edge;
  } else if (browserInfo.includes("Chrome")) {
    browser = platformInfo.chrome;
  } else if (browserInfo.includes("Firefox")) {
    browser = platformInfo.firefox;
  } else if (
    !browserInfo.includes("Chrome") &&
    browserInfo.includes("Safari")
  ) {
    browser = platformInfo.safari;
  }

  return browser;
};

export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
