import { call, put, select } from "redux-saga/effects";

import { takeLatest } from "redux-saga/effects";
import * as TYPES from "./ReverseType";
import API from "./ReverseApis";

// Util
import { dispatchSnackbarError } from "../../utils/Shared";
import { actionUpdateLocation } from "../GlobalUi/GlobalUiAction";
import { convertDataPoint } from "../../utils/convertDataPoint";

const getTabList = (state) => state.globalUi.tabList;

export function* sagasRequestReverse(action) {
  try {
    const {
      latitude,
      longitude,
      config = {},
      callback,
      ...props
    } = action.payload;

    const response = yield call(API.getReverseRequest, {
      lat: latitude,
      lng: longitude,
    });
    // TODO NamVH: có thể sử dụng thêm type để định hướng các trường hợp khác nhau.
    const data = response.data?.data.addresses?.[0];
    const tabList = yield select(getTabList);
    const payload = {
      data: convertDataPoint({ element: data, tabList }),
      ...props,
    };
    yield call(callback, payload);
  } catch (err) {
    const _data = err.response && err.response.data;
    dispatchSnackbarError(_data);
  }
}

// TODO NamVH: Cần chuẩn hóa lại tên và chuyển watcher and worker về đúng thư mục.
export function* ReverseSaga() {
  yield takeLatest(TYPES.GET_REVERSE, sagasRequestReverse);
}

export function* sagasRequestReverseV2(action) {
  try {
    const { latitude, longitude } = action.payload;
    const response = yield call(
      API.getReverseV2Request,
      `lat=${latitude}&lng=${longitude}`
    );
    const data = response.data;
    yield put(actionUpdateLocation(data.data));
  } catch (err) {
    const _data = err.response && err.response.data;
    dispatchSnackbarError(_data);
  }
}

// TODO NamVH: Cần chuẩn hóa lại tên và chuyển watcher and worker về đúng thư mục.
export function* ReverseV2Saga() {
  yield takeLatest(TYPES.GET_REVERSE_V2, sagasRequestReverseV2);
}
