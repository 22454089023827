import { axiosInstance, geoInstance } from "../../network/apis";
import {
  API_KEY_DETAIL,
  API_KEY_SEARCH,
  API_KEY_SUGGESTION,
  REVERVE_PROXY,
  REVERVE_PROXY_API_KEY,
} from "../../utils/Constants";

const headerDefault = {
  "Content-Type": "application/json",
};

const headers = {
  "Content-Type": "application/json",
  "api-key": API_KEY_SEARCH,
};

const headerSearchDetail = {
  "Content-Type": "application/json",
  "api-key": API_KEY_DETAIL,
};

const headerSuggestion = {
  "Content-Type": "application/json",
  apikey: API_KEY_SUGGESTION,
};

const headerSearchNear = {
  "Content-Type": "application/json",
  "api-key": "SkX9SpdxzZPdF3D8",
};

const getSearchRequest = async (params) => {
  return await axiosInstance.get(`/pregeo/suggest${params}`, {
    headers: headerSuggestion,
  });
};

const getSearchByTextRequest = async (params) => {
  return await axiosInstance.get(
    `${REVERVE_PROXY}/spaces/api/v3/geocode?${params}`,
    {
      headers: {
        ...headerDefault,
        "x-api-key": REVERVE_PROXY_API_KEY,
      },
    }
  );
};

const getSearchLatLongRequest = async (params) => {
  return await axiosInstance.get(`/query${params}`, { headers });
};

const getSearchDetailRequest = async (params) => {
  return await axiosInstance.get(`/details.php${params}`, {
    headers: headerSearchDetail,
  });
};

const getPointInMap = (params) => {
  return axiosInstance.get(`/api/v1/nearbysearch?${params}`, {
    headers: headerSearchNear,
  });
};

const getPointInMapV2 = (params) => {
  return axiosInstance.get(`/api/v1/nearhamlet?${params}`, {
    headers: headerSearchNear,
  });
};

const SearchApis = {
  getSearchRequest,
  getSearchByTextRequest,
  getSearchLatLongRequest,
  getSearchDetailRequest,
  getPointInMap,
  getPointInMapV2,
  getSearchListByText: async (params) =>
    await geoInstance.get(`${REVERVE_PROXY}/spaces/api/v3/geocode`, {
      params,
      headers: {
        "x-api-key": REVERVE_PROXY_API_KEY,
      },
    }),
};

export default SearchApis;
